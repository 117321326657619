import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useThemeChanger from "../../pages/home/hooks/useThemeChanger";

interface ScrollToTopProps {
  children: React.ReactNode;
}

export default function ScrollToTop({ children }: ScrollToTopProps) {
  const { pathname } = useLocation();
  const {isContrast} = useThemeChanger()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <div className={isContrast ? "contrastImg" : ""}>{children}</div>;
}