import { useMutation } from "@tanstack/react-query";
import API from "../../client/api";

export const postVisitorMutation = async (langid: number): Promise<number> => {
  const response = await API.post(`InsertVisitorNumber?lang=${langid}`, {});
  if(langid === 1){
    sessionStorage.setItem("enVisited", response.data as string);
  }
  else{
    sessionStorage.setItem("arVisited", response.data as string);
  }
  return response?.data;
};

const useVisitorsMutation = (langid: number) => {
  const { data, isError, error, isLoading, mutate } = useMutation<
    Number,
    Error,
    Number
  >(() => postVisitorMutation(langid));

  const postVisitor = async () => {
    await mutate(langid);
  };
  return {
    postVisitor,
    response: data,
    isError,
    error,
    isLoading,
  };
};

export default useVisitorsMutation;
