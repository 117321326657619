import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../../../client/api";
import { POLLSPAGE } from "../../../shared/queryKeyes";
export type PollsData = {
  pollID: number;
  pollOptionID: number;
};

type AddPollResponse = {
  status: string;
  messageEn: string;
  messageAr: string;
};

export const pollMutation = async (data: PollsData): Promise<AddPollResponse> => {
  const response = await API.post(`Poll/PollAddResult?Lang=${1}`, {
    pollID: data.pollID,
    pollOptionID: data.pollOptionID,
  });
  return response.data;
};
const usePollsMutation = () => {
  const queryClient = useQueryClient();
  const { data, isError, error, isLoading, mutate } = useMutation<
    AddPollResponse,
    Error,
    PollsData
  >(pollMutation);

  const submit = async (dataPassed: PollsData) => {
    await mutate(dataPassed);
    await queryClient.refetchQueries([POLLSPAGE]);
  };
  return {
    submit,
    isPollsMutationError: isError,
    pollsMutationError: error,
    pollsMutationIsLoading: isLoading,
    pollsMutationData: data,
  };
};

export default usePollsMutation;
