import styled from "@emotion/styled";
import {
  AccountTree,
  Close,
  HelpOutline,
  MailOutline,
  ReportGmailerrorred,
  Settings,
  Wifi,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useContext } from "react";
import { COLORS } from "../../../constants";
import SitemapIcon from "../../svg/SitemapIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageID } from "../../../hooks/useLanguageID";
import useSendToFriendMutation, {
  SendToFreindData,
} from "../../../hooks/useSendToFriendMutation";
import { toast } from "react-toastify";
import { ThemeContext } from "../../../../context/ThemeContext";
interface HeaderIconsSectionProp {
  handleDrawerOpen: () => void;
}
const StyledIcons = styled(Box)(({ theme }) => ({
  display: "flex",
  // gap: "10px",
}));
const SendToFreindDataInit: SendToFreindData = {
  sender_Name: "",
  sender_Email: "",
  receiver_Name: "",
  receiver_Email: "",
  urlToSend: document.URL,
  source: "",
  languageCode: "",
};
const HeaderIconsSection: React.FC<HeaderIconsSectionProp> = (props) => {
  const [sendToFriendDialog, setSendToFriendDialog] = React.useState(false);
  const [submitValue, setSubmitValue] =
    React.useState<SendToFreindData>(SendToFreindDataInit);

  const {isStartTour,startTourSetGet} = useContext(ThemeContext)
  const navigate = useNavigate();
  const { t } = useTranslation();
  const langId = useLanguageID();

  const { submit, sendtoFriendMutationData } = useSendToFriendMutation();
  const handleOpenDialog = () => {
    setSendToFriendDialog(true);
    setSubmitValue(SendToFreindDataInit);
  };
  const handleCloseDialog = () => {
    setSendToFriendDialog(false);
    setSubmitValue(SendToFreindDataInit);
  };
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSubmitValue({ ...submitValue, [event.target.name]: event.target.value });
  };
  const onSubmit = async () => {
    await submit(submitValue);

    handleCloseDialog()
  };
  const handleTour = () => {
    startTourSetGet(true);
  }
  React.useEffect(() => {
    if (langId === 1) {
      toast(sendtoFriendMutationData?.messageEn, {
        type:
          sendtoFriendMutationData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast(sendtoFriendMutationData?.messageAr, {
        type:
          sendtoFriendMutationData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [sendtoFriendMutationData?.status]);

  const disabledSendbuttom =
    submitValue.sender_Name === "" ||
    submitValue.sender_Email === "" ||
    submitValue.receiver_Name === "" ||
    submitValue.receiver_Email === "";
  return (
    <>
      <StyledIcons sx={{ display: { xs: "none", md: "flex" } }}>
        <IconButton title={t('guided_tour')} onClick={handleTour}>
          <ReportGmailerrorred sx={{ color: COLORS.white }} />
        </IconButton>
        {/* <IconButton title={t('sendtofreind')} onClick={handleOpenDialog}>
          <MailOutline sx={{ color: COLORS.white }} />
        </IconButton>
        <IconButton title={t('')} onClick={() => {}}>
          <HelpOutline sx={{ color: COLORS.white }} />
        </IconButton> */}
        <IconButton title={t('sitemap')} onClick={() => navigate("sitemap")}>
          <SitemapIcon sx={{ color: COLORS.white }} />
        </IconButton>
        <IconButton title={t('accessibility_options')} onClick={props.handleDrawerOpen}>
          <Settings sx={{ color: COLORS.white }} />
        </IconButton>
      </StyledIcons>
      <Dialog
        open={sendToFriendDialog}
        maxWidth={"xs"}
        fullWidth
        sx={{ zIndex: 1302, top: "-25%", left: langId === 1 ? "40%" : "-40%" }}
      >
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography fontWeight={'bold'} variant="h6">{t("sendtofreind")}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <TextField
              placeholder={t("enteryourname")}
              name="sender_Name"
              onChange={(event) => onChangeHandler(event)}
            />
            <TextField
              placeholder={t("enteryouremail")}
              name="sender_Email"
              onChange={(event) => onChangeHandler(event)}
            />
            <TextField
              placeholder={t("enterfriendname")}
              name="receiver_Name"
              onChange={(event) => onChangeHandler(event)}
            />
            <TextField
              placeholder={t("enterfriendemail")}
              name="receiver_Email"
              onChange={(event) => onChangeHandler(event)}
            />
          </Stack>
          <Box display={"flex"} justifyContent={"space-evenly"} mt={2}>
            <Button
            disabled={disabledSendbuttom}
              variant="contained"
              sx={{ color: COLORS.white }}
              onClick={onSubmit}
            >
              {t("sendbutton")}
            </Button>
            <Button
              variant="contained"
              sx={{ color: COLORS.white }}
              onClick={handleCloseDialog}
            >
              {t("closebutton")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HeaderIconsSection;
