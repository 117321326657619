import { useQuery } from "@tanstack/react-query";
import { GenericContentDM } from "../types/index";
import API from "../../client/api";
import { GENERICCONTENTBYUNUQIENAME } from "../queryKeyes";


const genericContentFetching = async (
  languageId: number,
  uniqueName: string
): Promise<GenericContentDM> => {
  const response = await API.get(
    `GenericContent/ContentByUniqueName?uniqueName=${uniqueName}&langId=${languageId}`
  );
  return response.data;
};
const useGenericContent = (languageId: number, uniqueName: string) => {
  return useQuery<GenericContentDM, Error>(
    [GENERICCONTENTBYUNUQIENAME, languageId, uniqueName],
    () => genericContentFetching(languageId, uniqueName)
  );
};

export default useGenericContent;
