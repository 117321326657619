import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import useComplaintTypes from "./hooks/useComplaintTypes";
import { DropDownVM } from "../homeIndex/types";
import SubPageTitle from "../../shared/components/typographies/SubPageTitle";
import { COLORS } from "../../shared/constants";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import { ComplaintsPost } from "./types";
import { initComplaintsPost } from "./utils";
import useComplaintsMutation from "./hooks/useComplaintsMutation";
import { toast } from "react-toastify";
import { emailValidation } from "../../shared/utils";
import { useTranslation } from "react-i18next";
import useGenericContent from "../../shared/hooks/useGenericContent";
import SearchNewsBanner from "../newsList/components/SearchNewsBanner";
interface ComplaintsProp {}

const Complaints: React.FC<ComplaintsProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const languageId = useLanguageID();
  const [formState, setFormState] =
    React.useState<ComplaintsPost>(initComplaintsPost);
  const { data, isLoading, isError, error } = useComplaintTypes(languageId);
  const genericContentQuery = useGenericContent(
    languageId,
    "suggestions.complaints"
  );
  const { submit, ComplaintMutationData } = useComplaintsMutation();
  useEffect(() => {
    setFormState({ ...formState, langId: languageId });
  }, [languageId]);
  React.useEffect(() => {
    if (languageId === 1) {
      toast(ComplaintMutationData?.messageEn, {
        type:
          ComplaintMutationData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast(ComplaintMutationData?.messageAr, {
        type:
          ComplaintMutationData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [ComplaintMutationData?.status]);
  if (isLoading || genericContentQuery.isLoading) {
    return <CustomSkeleton />;
  }
  if (isError || genericContentQuery.isError) {
    return null;
  }
  const handleTypeChange = (
    data: { label: string; uniqueName: string } | null
  ) => {
    setFormState({
      ...formState,
      categoryUniqueName: data?.uniqueName ?? "",
    });
  };
  const handletextInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  const types: DropDownVM[] | undefined = data?.map((item) => {
    const types_vm: DropDownVM = {
      label: item.name,
      uniqueName: item.uniqueName,
    };
    return types_vm;
  });
  const defaulttype: DropDownVM = {
    label: t("selecttype"),
    uniqueName: "",
  };
  const handleSubmit = async () => {
    await submit(formState);
    setFormState(initComplaintsPost);
    window.scrollTo(0, 300);
  };
  const validateEmail = emailValidation.test(formState.email);
  const validateForm =
    formState.categoryUniqueName === "" ||
    formState.name === "" ||
    formState.email === "" ||
    formState.phone === "" ||
    formState.comment === "";
    // const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
    // const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z\s]+$/;
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z\s\u0600-\u06FF]+$/;
  return (
    <>
     <SearchNewsBanner
        mainImageFullUrl={genericContentQuery.data?.mainImageFullUrl}
        bannerTitle={t("")}
        showIcons={false}
        showPath={false}
      />
    <Box marginX={"8%"}>
      <Box mt={4}>
        {/* <SubPageTitle variant="h5" title={t("contactus.complaints")} /> */}
        <SubPageTitle variant="h5" title={genericContentQuery.data.title} />
        <Typography
          color={theme.palette.primary.main}
          variant="h6"
          dangerouslySetInnerHTML={{
            __html: genericContentQuery.data?.contentData!,
          }}
        />
      </Box>
      <Stack spacing={2} mt={6}>
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            {t("type")}
            <span style={{ color: "red", marginInline: 2 }}>*</span>
          </Typography>
          <Autocomplete
            onChange={(event, cat) => handleTypeChange(cat)}
            defaultValue={defaulttype}
            disablePortal
            size="small"
            id="combo-box-demo"
            options={types ?? []}
            sx={{ width: { xs: "100%", md: "60%", lg: "50%", xl: "50%" } }}
            renderInput={(params) => (
              <TextField sx={{ textAlign: "center" }} {...params} />
            )}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            {t("fullname")}
            <span style={{ color: "red", marginInline: 2 }}>*</span>
          </Typography>
          <TextField
          value={formState.name}
            name="name"
            sx={{ width: { xs: "100%", md: "60%", lg: "50%", xl: "50%" } }}
            size="small"
            variant="outlined"
            type="text"
            onKeyDown={(event) => {
              if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={handletextInputChange}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            {t("emailaddress")}
            <span style={{ color: "red", marginInline: 2 }}>*</span>
          </Typography>
          <TextField
          value={formState.email}
            name="email"
            sx={{ width: { xs: "100%", md: "60%", lg: "50%", xl: "50%" } }}
            size="small"
            variant="outlined"
            onChange={handletextInputChange}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            {t("phone")}
            <span style={{ color: "red", marginInline: 2 }}>*</span>
          </Typography>
          <TextField
          value={formState.phone}
            type="number"
            name="phone"
            sx={{ width: { xs: "100%", md: "60%", lg: "50%", xl: "50%" } }}
            size="small"
            variant="outlined"
            onChange={handletextInputChange}
          />
        </Stack>
        {/* 
        <Stack spacing={1}>
          <Typography component={"span"} variant="subtitle2">
            {t("subject")}
            <span style={{ color: "red", marginInline: 2 }}>*</span>
          </Typography>
          <TextField
            name="userStatus"
            sx={{ width: { xs: "100%", md: "60%", lg: "50%", xl: "50%" } }}
            size="small"
            variant="outlined"
            onChange={handletextInputChange}
          />
        </Stack>
         */}
        <Stack spacing={1}>
          <Typography variant="subtitle2">
            {t("comments")}
            <span style={{ color: "red", marginInline: 2 }}>*</span>
          </Typography>
          <TextField
          value={formState.comment}
            name="comment"
            multiline
            rows={4}
            sx={{ width: { xs: "100%", md: "60%", lg: "50%", xl: "50%" } }}
            size="small"
            variant="outlined"
            onChange={handletextInputChange}
          />
        </Stack>
        <Button
          disabled={!validateEmail || validateForm}
          variant="contained"
          sx={{ width: "20%", color: COLORS.white }}
          onClick={handleSubmit}
        >
          {t("submit")}
        </Button>
      </Stack>
    </Box>
    </>
  );
};

export default Complaints;
