import React from 'react'
import { Grid, Typography, useTheme } from '@mui/material'

import { COLORS } from '../../../../shared/constants';
import { RelatedHighlight } from '../../types';
import { useTranslation } from 'react-i18next';
interface FooterHighlightsProp {
    relatedHIghlights: RelatedHighlight[],
    visitors: number,
};
const FooterHighlights: React.FC<FooterHighlightsProp> = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
  return (
    <Grid
    container
    sx={{ backgroundColor: theme.palette.primary.main }}
    display={"flex"}
    justifyContent={"space-around"}
    alignItems={"center"}
    paddingX={"6%"}
    paddingY={1}
    gap={1}
  >
    <Grid item xs={12} md={6}>
      <Typography
        color={COLORS.white}
        dangerouslySetInnerHTML={{
          __html:
            props.relatedHIghlights
              ?.find((a) => a.title === "copy.right")
              ?.description?.replace(
                "#YEAR#",
                new Date().getFullYear().toString()
              ) ?? "",
        }}
      />
      <Typography color={'white'}>
        {`${t('visitors')}: ${props.visitors}`}
      </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <Typography
        color={COLORS.white}
        dangerouslySetInnerHTML={{
          __html:
            props.relatedHIghlights?.find(
              (a) => a.title === "site.support"
            )?.description ?? "",
        }}
      />
      {/* <Typography color={COLORS.white} mt={2}>
        Total AVG : 214234
      </Typography> */}
    </Grid>
  </Grid>
  )
}

export default FooterHighlights