import { West, East } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constants";
interface SectionHeaderProp {
  firstLine: string;
  firstLineColor?: string;
  secondLine: string;
  thirdtLine: string;
  onClick?: () => void;
}
const SectionHeader: React.FC<SectionHeaderProp> = (props) => {
  const theme = useTheme();
  return (
    <Box>
      {/* <Typography
        variant="subtitle2"
        sx={{
          borderBottom: `1px solid ${props.firstLineColor}`,
          display: "inline-block",
          color: props.firstLineColor,
        }}
      >
        {props.firstLine}
      </Typography> */}
      <Typography
        variant="h4"
        sx={{
          display: "block",
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        {props.secondLine}
      </Typography>
      <Box mt={"1%"} display={"flex"} flexDirection={"row"}>
        <Button
        onClick={props.onClick ? props.onClick : () =>{}}
          sx={{ color: COLORS.black }}
          endIcon={theme.direction === "ltr" ? <East /> : <West />}
        >
          <Typography
            variant="subtitle2"
            sx={{
              borderBottom: "1px solid black",
              display: "inline-block",
            }}
          >
            {props.thirdtLine}
          </Typography>
        </Button>
        {/* <Typography
          variant="subtitle2"
          sx={{
            borderBottom: "1px solid black",
            display: "inline-block",
          }}
        >
          {props.thirdtLine}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            display: "inline-block",
            marginInline: '10px',
          }}
        >
          {theme.direction === "ltr" ? <East /> : <West />}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default SectionHeader;
