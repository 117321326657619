import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";

import { COLORS } from "../../../../shared/constants";

interface TryToAttendCardMediaProp {
  mainImageFullUrl: string;
  eventStartDateSplits: string[];
  eventEndDateSplits: string[];
}
const TryToAttendCardMedia: React.FC<TryToAttendCardMediaProp> = (props) => {
  return (
    <Box>
      <CardMedia
        component="img"
        sx={{
          maxHeight: 120,
          border: `4px solid ${COLORS.white}`,
          borderBottom: "none",
          position: "relative",
        }}
        image={props.mainImageFullUrl}
        alt="image"
      />
      <Box position={"absolute"} width={"100%"} top={85}>
        <Box display={"flex"} justifyContent={"space-between"} paddingX={2}>
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Typography color={COLORS.white} fontSize={20}>
                {props.eventStartDateSplits[2]}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} marginX={'4px'}>
              <Typography color={COLORS.white} fontSize={9}>
                {props.eventStartDateSplits[1]}
              </Typography>
              <Typography color={COLORS.white} fontSize={9}>
                {props.eventStartDateSplits[3]}
              </Typography>
            </Box>
          </Box>
          {/* <Typography color={COLORS.white}>{'------------->'}</Typography> */}
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Typography color={COLORS.white} fontSize={20}>
                {props.eventEndDateSplits[2]}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} marginX={'4px'}>
              <Typography color={COLORS.white} fontSize={9}>
                {props.eventEndDateSplits[1]}
              </Typography>
              <Typography color={COLORS.white} fontSize={9}>
                {props.eventEndDateSplits[3]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TryToAttendCardMedia;
