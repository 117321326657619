import API from "../../../client/api";
import { EventsPage } from "../types";
export const PAGE_SIZE = 5;

export const fetchEvents = async (
    pageParam = 1,
    langId: number,
    keyword: string,
    categoryUniqueName: string,
    dateFrom: string,
    dateTo: string
  ): Promise<EventsPage> => {
    const response = await API.get(
      `Events/Search?pageSize=${PAGE_SIZE}&pageNumber=${pageParam}
      &langId=${langId}&keyword=${keyword}&categoryUniqueName=${categoryUniqueName}
      &fromDate=${dateFrom}&toDate=${dateTo}`
      // &fromDate=2011-07-19T00:00:00&toDate=2019-07-19T00:00:00&`
    );
    return response.data;
  };