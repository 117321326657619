import { Box, Grid, IconButton } from "@mui/material";
import React from "react";
import { ImageGalleryItem } from "../types";
interface ImageGalleryContainerProp {
  result: ImageGalleryItem[];
  handleOpen: (index: number) => void;
}
const ImageGalleryContainer: React.FC<ImageGalleryContainerProp> = (props) => {
  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const imageStyle = {
    cursor: hovered ? "pointer" : "default",
  };
  return (
    <Box mt={5}>
      <Grid container>
        {props.result && props.result.length > 0 && (
          <Grid xs={12} item display={{ xs: "none", md: "block" }}>
            {/* <IconButton sx={{ width: "100%" }} onClick={() => handleOpen(0)}> */}
            <img
              width={"100%"}
              height={550}
              src={props.result[0].mainImageFullUrl}
            />
            {/* </IconButton> */}
          </Grid>
        )}
        {props.result.map((imgItem, index) => {
          return (
            <Grid
              xs={12}
              md={4}
              item
              sx={{
                transition: "0.5s",
                "&:hover": {
                  transform: "Scale(1.1)",
                  zIndex: 2,
                },
              }}
            >
              <Box height={{ xs: "auto", md: 300 }} p={1}>
                <img
                  style={imageStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  width={"100%"}
                  height={"100%"}
                  src={imgItem.mainImageFullUrl}
                  onClick={() => props.handleOpen(index)}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ImageGalleryContainer;
