import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { Banners } from "../types";
import { HOMETOPBANNERS } from "../../../shared/queryKeyes";

const homTopBannersFetching = async (
  languageId: number
): Promise<Banners[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=home.top.header&langId=${languageId}`
  );
  return response.data;
};
const useHomeTopBanners = (languageId: number) => {
  return useQuery<Banners[], Error>([HOMETOPBANNERS, languageId], () =>
    homTopBannersFetching(languageId)
  );
};

export default useHomeTopBanners;
