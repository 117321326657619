import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import SubPageTitle from "../../shared/components/typographies/SubPageTitle";
import usePolls from "./hooks/usePolls";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import NoMatch from "../noMatch/NoMatch";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import { COLORS } from "../../shared/constants";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import usePollsMutation, { PollsData } from "./hooks/usePollsMutation";
import usePollsCookies from "./hooks/usePollsCookies";
import HomeTopBannersIcons from "../homeIndex/components/pureComponents/HomeTopBannersIcons";

interface PollsProp {}
const Polls: React.FC<PollsProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const langId = useLanguageID();
  const [showResult, setShowResult] = React.useState(false);

  const { data, isLoading, isError, refetch } = usePolls(1, langId);
  const [submitValue, setSubmitValue] = React.useState<PollsData>({
    pollID: 0,
    pollOptionID: 0,
  });
  const { submit, pollsMutationData } = usePollsMutation();
  const { getPollsCookieValue, setPollsCookie } = usePollsCookies();

  const handleShowResult = () => {
    setShowResult(true);
  };
  const handleUnShowResult = () => {
    setShowResult(false);
    setSubmitValue({ pollID: 0, pollOptionID: 0 });
  };

  const onChangeRadioButtonHandler = (pollID: number, pollOptionID: number) => {
    setSubmitValue({ pollID, pollOptionID });
  };
  const submitHandler = async () => {
    await submit(submitValue);
    await setPollsCookie();
  };

  const pollsCookie = getPollsCookieValue();
  React.useEffect(() => {
    if (langId === 1) {
      toast(pollsMutationData?.messageEn, {
        type:
          pollsMutationData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast(pollsMutationData?.messageAr, {
        type:
          pollsMutationData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [pollsMutationData?.status]);
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <NoMatch />;
  }
  const { totalCount, result } = data;
  return (
    <Box marginX={"8%"} marginTop={5}>
      <Box>
        <Stack spacing={5} mb={1}>
          <SubPageTitle title={t("polls")} />
        </Stack>
      </Box>

      <Box mt={5}>
        {result.map((item, index) => (
          <Card key={index}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <ExpandCircleDownOutlined
                sx={{
                  color: COLORS.white,
                  width: 25,
                  height: 25,
                  marginInlineEnd: 1,
                }}
              />
              <Typography variant="h5" component="div" color={"white"}>
                {t("activepolls")}
              </Typography>
            </CardContent>
            <CardContent sx={{ borderBottom: `1px solid ${COLORS.secondary}` }}>
              <Typography variant="subtitle1" component="div">
                {item.question}
              </Typography>
            </CardContent>
            <CardContent>
              {pollsCookie === "true" || (showResult && item.pollResults) ? (
                <Stack spacing={2}>
                  {item.pollResults.map((pollResultItem, index) => (
                    <Box
                      key={index}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Box width={"20%"}>
                        <Typography>{pollResultItem.optionText}</Typography>
                      </Box>
                      <Box mx={5} width={"45%"}>
                        <Box
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            width: pollResultItem.voter,
                          }}
                        >
                          <Typography color={"primary"}>
                            {pollResultItem.voter}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography>{pollResultItem.voter}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              ) : (
                <RadioGroup
                  sx={{ display: "flex", flexDirection: "row" }}
                  onChange={(event) =>
                    onChangeRadioButtonHandler(item.id, +event.target.value)
                  }
                >
                  {item.pollOptions.map((answer, index) => (
                    <FormControlLabel
                      key={index}
                      value={answer.id}
                      control={<Radio />}
                      label={answer.option}
                    />
                  ))}
                </RadioGroup>
              )}
            </CardContent>
            {pollsCookie !== "true" && (
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 3,
                  borderTop: `1px solid ${COLORS.secondary}`,
                }}
              >
                {showResult ? (
                  <Button
                    variant="contained"
                    sx={{ color: COLORS.white }}
                    onClick={handleUnShowResult}
                  >
                    {t("back")}
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={
                        submitValue.pollID === 0 ||
                        submitValue.pollOptionID === 0
                      }
                      variant="contained"
                      sx={{ color: COLORS.white, marginInlineEnd: 1 }}
                      onClick={submitHandler}
                    >
                      {t("submit")}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ color: COLORS.white }}
                      onClick={handleShowResult}
                    >
                      {t("showresults")}
                    </Button>
                  </>
                )}
              </CardActions>
            )}
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Polls;
