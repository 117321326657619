import React from "react";
import { AppBar, Toolbar, Divider, Typography, Box } from "@mui/material";

import HeaderSearchSection from "./pureComponents/HeaderSearchSection";
import HeaderIconsSection from "./pureComponents/HeaderIconsSection";
import HeaderWeatherSection from "./pureComponents/HeaderWeatherSection";
import HeaderEmergencySection from "./pureComponents/HeaderEmergencySection";
import { COLORS } from "../../constants";
import LogoContainer from "./LogoContainer";
import NavigationBar from "./NavigationBar";
interface GuardAppBarProp {
  handleDrawerOpen: () => void;
}

const GuardAppBar: React.FC<GuardAppBarProp> = (props) => {
  return (
    <div className="ignore-print">
      <AppBar position="relative" elevation={0}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box display={'flex'} alignItems={'center'} id="personalization">
          <HeaderSearchSection />
          <HeaderIconsSection handleDrawerOpen={props.handleDrawerOpen} />
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              margin: "1%",
              backgroundColor: COLORS.white,
              display: { xs: "none", md: "flex" },
            }}
          />
          <HeaderWeatherSection />
          <HeaderEmergencySection />
        </Toolbar>
        <LogoContainer />
        <NavigationBar />
      </AppBar>
    </div>
  );
};

export default GuardAppBar;
