import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { COLORS } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import useSearchCategory from "../hooks/useSearchCategory";
import { AdvanceSearchStateVM } from "../types";

interface AdvanceSearchComponentProp {
  setAdvanceSearchFormState: React.Dispatch<
    React.SetStateAction<AdvanceSearchStateVM>
  >;
  advanceSearchFormState: AdvanceSearchStateVM;
  refetchHandler: () => void;
}

const AdvanceSearchComponent: React.FC<AdvanceSearchComponentProp> = (
  props
) => {
  const { searchvalue } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const languageId = useLanguageID();
  const [state, setState] =
    React.useState<AdvanceSearchStateVM>(props.advanceSearchFormState);
  const { data, isError, isLoading } = useSearchCategory(languageId);
  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      key: event.target.value,
    });
  };
  const handleCategoryChange = (
    option: { id: number; name: string } | null
  ) => {
    setState({
      ...state,
      CategoryID: option?.id ?? -1,
    });
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }
  if (isError) {
    return null;
  }
  const options =
    data?.map((item) => ({
      id: item.id,
      name: item.name,
    })) ?? [];
  const searchHandler = () => {
    props.setAdvanceSearchFormState(state)
    // props.refetchHandler()
  };
  const resetHandler = () => {
    setState({CategoryID:-1, key:searchvalue ?? ""})
    props.setAdvanceSearchFormState({CategoryID:-1, key:searchvalue ?? ""})
  };
  const defaultOption = options.find((option) => option.id === state.CategoryID);
  return (
    <>
      <Grid
        paddingX={"4%"}
        paddingTop={"3%"}
        paddingBottom={"4%"}
        container
        gap={2}
        sx={{
          backgroundImage: `linear-gradient(to bottom,${theme.palette.primary.main} , ${COLORS.white})`,
        }}
      >
        <Grid
          item
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={3}
        >
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
            <Typography variant="subtitle2">{t("search")}</Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              value={state.key}
              size="small"
              placeholder={t("enterkeyword")}
              variant="outlined"
              onChange={handleKeyChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              onChange={(event, cat) => handleCategoryChange(cat)}
              disablePortal
              size="small"
              id="combo-box-demo"
              options={options}
              getOptionLabel={(option) => option.name} // Specify the label property name
              value={defaultOption}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Button
              fullWidth
              onClick={searchHandler}
              variant="contained"
              sx={{ color: COLORS.white }}
            >
              {t("search")}
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              fullWidth
              onClick={resetHandler}
              variant="contained"
              sx={{ color: COLORS.white }}
            >
              {t("reset")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvanceSearchComponent;
