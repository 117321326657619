import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";

import { COLORS } from "../../../shared/constants";
import HomeTopBannersIcons from "../../homeIndex/components/pureComponents/HomeTopBannersIcons";
import { useTranslation } from "react-i18next";

const newsListBanner = require("../../../assets/images/events556.png");
interface SearchEventsBannerProp {
  mainImageFullUrl?: string,
  bannerTitle: string,
  showIconsAndPath: boolean
}
const SearchEventsBanner: React.FC<SearchEventsBannerProp> = (props) => {
    const {t} = useTranslation();
  return (
    <Box>
      <Box position={"relative"} sx={{ backgroundColor: COLORS.secondary }}>
        <CardMedia
          component="img"
          sx={{
            borderBottom: "none",
            backgroundColor: COLORS.black,
            maxHeight:375,
          }}
          image={props.mainImageFullUrl ? props.mainImageFullUrl : newsListBanner}
          alt="image"
        />
        {props.showIconsAndPath &&  <Box p={2}>
            <Typography variant="subtitle2">{t('eventurhere')}</Typography>
        </Box>}
        <Box
        position={"absolute"}
        top={{
          xs: "10%",
          sm: "10%",
          md: "7%",
          lg: "8%",
          xl: "8%",
        }}
        width={"100%"}
      >
        <Box display={"flex"} justifyContent={"space-between"} paddingX={2}>
          <Box width={"50%"} mt={7}>
            <Typography
              fontSize={"1.65vw"}
              variant={"h5"}
              color={COLORS.white}
              marginX={4}
            >
              {props.bannerTitle}
            </Typography>
          </Box>
          {/* {props.showIconsAndPath && <HomeTopBannersIcons />} */}
        </Box>
      </Box>
      </Box>
    
    </Box>
  );
};

export default SearchEventsBanner;
