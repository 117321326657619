import { SvgIcon } from "@mui/material";

export default function ComplaintsIcon(props: any) {
  return (
    // <SvgIcon {...props}>
      <svg
      style={{marginLeft:4,marginTop:2}}
        xmlns="http://www.w3.org/2000/svg"
        id="ctl00_ctrlContentArea_NewsList2013724114611_rptContactChannel_ctl02_imgIcon"
        enable-background="new 0 0 512 512"
        height="21"
        viewBox="0 0 512 512"
        width="21"
        href="https://moi.gov.ae//DataFolder/Images/2020/complaint.svg"
      >
        <g>
          <path d="m478.179 161.787c-10.956-10.964-28.373-11.653-40.151-2.056-1.703 1.388-.384.165-26.317 26.098l67.316 67.317 24.193-23.891c11.697-11.697 11.697-30.729 0-42.427z"></path>
          <path d="m273.667 325.148-39.598 84.854c-2.667 5.717-1.474 12.49 2.986 16.95 4.46 4.461 11.236 5.653 16.95 2.986l84.854-39.599c3.357-1.567-6.806 7.945 118.822-116.114l-67.183-67.184c-124.558 124.559-115.233 114.684-116.831 118.107z"></path>
          <path d="m364.13 408.771c-3.674 3.627-7.908 6.572-12.585 8.755l-84.854 39.599c-16.686 7.787-37.071 4.822-50.848-8.958-13.331-13.33-16.932-33.765-8.96-50.851l39.599-84.856c2.224-4.765 5.238-9.067 8.957-12.787l127.567-127.567v-127.106c0-24.813-20.187-45-45-45h-293c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h293c24.813 0 45-20.187 45-45v-76.871zm-284.123-311.771h224c8.284 0 15 6.716 15 15s-6.716 15-15 15h-224c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 80h176c8.284 0 15 6.716 15 15s-6.716 15-15 15h-176c-8.284 0-15-6.716-15-15s6.716-15 15-15zm-15 95c0-8.284 6.716-15 15-15h128c8.284 0 15 6.716 15 15s-6.716 15-15 15h-128c-8.284 0-15-6.716-15-15z"></path>
        </g>
      </svg>
    // </SvgIcon>
  );
}
// <svg
// xmlns="http://www.w3.org/2000/svg"
// id="ctl00_ctrlContentArea_NewsList2013724114611_rptContactChannel_ctl02_imgIcon"
// enable-background="new 0 0 512 512"
// height="512"
// viewBox="0 0 512 512"
// width="512"
// src="https://moi.gov.ae//DataFolder/Images/2020/complaint.svg">
//   <g>
//     <path d="m478.179 161.787c-10.956-10.964-28.373-11.653-40.151-2.056-1.703 1.388-.384.165-26.317 26.098l67.316 67.317 24.193-23.891c11.697-11.697 11.697-30.729 0-42.427z"></path><path d="m273.667 325.148-39.598 84.854c-2.667 5.717-1.474 12.49 2.986 16.95 4.46 4.461 11.236 5.653 16.95 2.986l84.854-39.599c3.357-1.567-6.806 7.945 118.822-116.114l-67.183-67.184c-124.558 124.559-115.233 114.684-116.831 118.107z"></path><path d="m364.13 408.771c-3.674 3.627-7.908 6.572-12.585 8.755l-84.854 39.599c-16.686 7.787-37.071 4.822-50.848-8.958-13.331-13.33-16.932-33.765-8.96-50.851l39.599-84.856c2.224-4.765 5.238-9.067 8.957-12.787l127.567-127.567v-127.106c0-24.813-20.187-45-45-45h-293c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h293c24.813 0 45-20.187 45-45v-76.871zm-284.123-311.771h224c8.284 0 15 6.716 15 15s-6.716 15-15 15h-224c-8.284 0-15-6.716-15-15s6.716-15 15-15zm0 80h176c8.284 0 15 6.716 15 15s-6.716 15-15 15h-176c-8.284 0-15-6.716-15-15s6.716-15 15-15zm-15 95c0-8.284 6.716-15 15-15h128c8.284 0 15 6.716 15 15s-6.716 15-15 15h-128c-8.284 0-15-6.716-15-15z"></path>
//     </g>
//     </svg>
