import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { COMPLAINTS } from "../../../shared/queryKeyes";
import { ComplaintTypesDM } from "../../homeIndex/types";

  const complaintTypesFetching = async (languageId: number): Promise<ComplaintTypesDM[]> => {
    const response = await API.get(`Feedback/Categories?langId=${languageId}`);
    return response.data;
  };
  const useComplaintTypes = (languageId: number) => {
    return useQuery<ComplaintTypesDM[],Error>([COMPLAINTS, languageId], () => complaintTypesFetching(languageId));
  };

  export default useComplaintTypes;