import { useTranslation } from "react-i18next";
import { Step } from "react-joyride";
const useTourSteps = () => {
  const { t } = useTranslation();

  const NGsteps: Step[] = [
    {
      content: <h2>{t("guided_tour_title")}</h2>,
      placement: "center",
      target: "body",
    },
    {
      content: <p>{t("personalization_content")}</p>,
      placement: "top",
      target: "#personalization",
      title: t("personalization_title"),
    },
    {
      content: <p>{t("mainmenu_content")}</p>,
      placement: "top",
      target: "#main-menu",
      title: t("mainmenu_title"),
    },
    {
      content: (
        <p>
          {t('our_leaders_content')}
        </p>
      ),
      placement: "top",
      target: "#our-leaders",
      title: t('our_leaders_title'),
    },
    {
      content: <p>{t('news_events_content')}</p>,
      placement: "top",
      target: "#news-events",
      title: t('news_events_title'),
    },
    {
      content: <p>{t('latest_events_content')}</p>,
      placement: "top",
      target: "#events",
      title: t('latest_events_title'),
    },
    {
      content: (
        <p>
          {t('media_center_content')}
        </p>
      ),
      placement: "top",
      target: "#media-center",
      title: t('media_center_title'),
    },
    {
      content: (
        <p>
          {t('newsletter_content')}
        </p>
      ),
      placement: "top",
      target: "#newsletter",
      title: t('newsletter_title'),
    },
    {
      content: (
        <p>
          {t('social_media_content')}
        </p>
      ),
      placement: "top",
      target: "#social-media",
      title: t('social_media_title'),
    },
  ];
  return NGsteps;
};

export default useTourSteps;
