import { Typography } from "@mui/material";
import React from "react";
interface SubPageTitleProp {
  title: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}
const SubPageTitle: React.FC<SubPageTitleProp> = (props) => {
  return (
    <Typography
      variant={props.variant ? props.variant : 'h3'}
      fontWeight={"bold"}
      sx={{
        display: "block",
        marginTop: 2,
        marginBottom: 3,
      }}
      color={"primary"}
    >
      {props.title}
    </Typography>
  );
};

export default SubPageTitle;
