import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { POLLSPAGE } from "../../../shared/queryKeyes";
import { PollsPage } from "../types";

export const POLLS_SIZE = 12;
const pollsFetching = async (
  pageNumber = 1,
  langId: number,
): Promise<PollsPage> => {
  const response = await API.get(
    `Poll/Search?pageSize=${POLLS_SIZE}&pageNumber=${pageNumber}
    &langId=${langId}`
  );
  return response.data;
};
const usePolls = (pageNumber: number, langId: number) => {
  return useQuery<PollsPage, Error>([POLLSPAGE, pageNumber, langId], () =>
  pollsFetching(pageNumber, langId)
  );
};

export default usePolls;
