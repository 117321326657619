import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer } from "react-toastify";
import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/GlobalStyle.css";

import { changeThemeProps } from "./shared/utils";
import { ThemeContext } from "./context/ThemeContext";
import { goldenTheme } from "./theme/theme";
import RouterProv from "./routes/RouterProv";

import "react-toastify/dist/ReactToastify.css";
import useGuidedTourCookie from "./shared/hooks/useGuidedTourCookie";

const queryClient = new QueryClient();
function App() {
  const themeJson = localStorage.getItem("customeTheme");
  const storageTheme = createTheme(
    themeJson ? JSON.parse(themeJson) : goldenTheme
  );
  const { getGuidedTourCookieValue } = useGuidedTourCookie();
  const [themeName, _setThemeName] = React.useState(storageTheme);
  const [contrastTheme, _setContrastTheme] = React.useState(false);
  const [startTour, _setStartTour] = React.useState(getGuidedTourCookieValue() === "true" ? false : true);
  const theme = changeThemeProps(themeName);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider
        value={{
          changeTheme: _setThemeName,
          themeData: theme,
          contrastSetGet: _setContrastTheme,
          isContrast: contrastTheme,
          startTourSetGet : _setStartTour,
          isStartTour: startTour,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <Box sx={{ direction: theme.direction }}>
              <RouterProv />
              <ToastContainer limit={3} />
            </Box>
          </ThemeProvider>
        </LocalizationProvider>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
