// import { useMutation } from 'react-query';
import { useMutation } from "@tanstack/react-query";
import API from "../../client/api";
type LoginData = {
  userName: string;
  password: string;
};

type LoginResponse = {
  token: string;
  //   refreshToken: string;
};

export const loginMutation = async (data: LoginData): Promise<string> => {
  const response = await API.post("authentication/authenticate", {
    userName: process.env.REACT_APP_USER_NAME!,
    password: process.env.REACT_APP_PASSWORD!,
  });
  localStorage.setItem("token", response.data as string);
  const localStorageToken = localStorage.getItem("token");
  // console.log('token from loginMutation',localStorageToken)
  return response?.data;
};

const useLoginMutation = () => {
  const { data, isError, error, isLoading, mutate } = useMutation<
    String,
    Error,
    LoginData
  >(loginMutation);

  const login = async () => {
    const loginData = {
      userName: process.env.REACT_APP_USER_NAME!,
      password: process.env.REACT_APP_PASSWORD!,
    };
    await mutate(loginData);
  };
  const refreshToken = async () => {
    await login();
  };
  return {
    login,
    token: data,
    // refreshToken: data?.refreshToken || null,
    refreshToken: refreshToken,
    isError,
    error,
    isLoading,
  };
};

export default useLoginMutation;
