import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { ADVANCESEARCHPAGE } from "../../../shared/queryKeyes";
import { AdvanceSearchPage } from "../types";

export const ADVANCE_SEARCH_PAGE_SIZE = 12;
const advanceSearchFetching = async (
  Pageindex = 1,
  langId: number,
  CategoryID: number,
  keyword: string
): Promise<AdvanceSearchPage> => {
  const response = await API.get(
    `Search/AdvanceSearch?keyword=${keyword}&CategoryID=${CategoryID}
    &pageSize=${ADVANCE_SEARCH_PAGE_SIZE}&Pageindex=${Pageindex}
    &lang=${langId}&`
  );
  return response.data;
};
const useAdvanceSearch = (
    Pageindex = 1,
    langId: number,
    CategoryID: number,
    keyword: string
) => {
  return useQuery<AdvanceSearchPage, Error>(
    [ADVANCESEARCHPAGE, Pageindex,CategoryID, keyword,langId],
    () => advanceSearchFetching(Pageindex, langId,CategoryID, keyword)
  );
};

export default useAdvanceSearch;
