import React from "react";
import { COLORS } from "../../constants";
import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
const UAELogo = require("../../../assets/images/modlogo2.jpg");
const AppLogo = require("../../../assets/images/logoVertical.png");

const LogoBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}));
interface LogoContainerProp {}
const LogoContainer: React.FC<LogoContainerProp> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const url =
    theme.direction === "ltr"
      ? "https://www.mod.gov.ae"
      : "https://www.mod.gov.ae/ar";
  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        paddingX: "7%",
        paddingY: "1%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        direction: "ltr",
      }}
    >
      <Box
        onClick={() => window.open(url, "_blank")}
        sx={{
          // width: "150px",
          width: {xs:"75px",sm:"150px"},
          height: "auto",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <img src={UAELogo} alt="Logo" style={{ width: "100%" }} />
      </Box>
      <Box onClick={() => navigate("")} sx={{
          width: {xs:"90px",sm:"148px"},
          height: "auto",
          "&:hover": {
            cursor: "pointer",
          },
        }}>
        <img
        width={'100%'}
          src={AppLogo}
          alt="Logo"
          // style={{ height: "95.51px", width: "297.76px" }}
        />
      </Box>
    </Box>
  );
};

export default LogoContainer;
