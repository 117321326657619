import React from "react";
import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { CalendarMonth, Directions, History } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import SectionHeader from "../../../shared/components/sectionHeader/SectionHeader";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import useEventsLatest from "../hooks/useEventsLatest";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import TryToAttendCardMedia from "./pureComponents/TryToAttendCardMedia";
import { COLORS } from "../../../shared/constants";
import { useNavigate } from "react-router-dom";
import { DateFormatedByLanguage } from "../../../shared/utils";
import { EventDM } from "../types";

import { createEvent, EventAttributes } from "ics";

const EventsLogo = require("../../../assets/images/EventsLogo.png");
const AppLogoAr = require("../../../assets/images/AppLogoAr.png");

interface TryToAttendSectionProp {}

const TryToAttendSection: React.FC<TryToAttendSectionProp> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useEventsLatest(languageId);

  const addToCalanderHandler = (item: EventDM) => {
    const sDate = new Date(item.eventStartDate);
    const eDate = new Date(item.eventEndDate);
    const timeDiff = eDate.getTime() - sDate.getTime();

    // Convert milliseconds to minutes
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    const event: EventAttributes = {
      title: item.title,
      description: item.title,
      location: item.location,
      start: [
        sDate.getFullYear(),
        sDate.getMonth() + 1,
        sDate.getDate(),
        sDate.getHours(),
        sDate.getMinutes(),
      ],
      duration: { minutes: minutesDiff },
    };

    createEvent(event, (error, value) => {
      if (error) {
        console.error(error);
        return;
      }

      const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${item.title}.ics`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return null;
  }
  return (
    <Box mt={4} id="events">
      <Box
        sx={{
          backgroundColor: COLORS.primary,
          backgroundImage: `linear-gradient(to bottom,${theme.palette.primary.main} , ${COLORS.white})`,
        }}
        display={"flex"}
        justifyContent={"space-between"}
        // flexDirection={"row-reverse"}
        paddingY={4}
        paddingX={"8%"}
      >
        <SectionHeader
          firstLine={t("featuredevents")}
          firstLineColor={COLORS.white}
          secondLine={t("trytoattend")}
          thirdtLine={t("viewallevents")}
          onClick={() => navigate("events")}
        />
        <CardMedia
          component="img"
          sx={{
            borderBottom: "none",
            position: "relative",
            width: 283,
            height: 234,
            alignSelf: "end",
            display: {
              xs: "none",
              sm: "block",
            },
          }}
          image={EventsLogo}
          alt="image"
        />
      </Box>
      <Box
        marginX={"8%"}
        sx={{
          marginTop: {
            xs: -6,
            sm: -15,
          },
        }}
      >
        <Grid
          container
          sx={{ marginTop: 5 }}
          gap={6}
          justifyContent={{ xs: "center", sm: "flex-start" }}
          // marginX={"4%"}
        >
          {data?.map((item) => {
            const eventStartDate = new Date(item.eventStartDate);
            const eventStartDateSplits = eventStartDate
              .toDateString()
              .split(" ");
            const eventEndDate = new Date(item.eventEndDate);
            const eventEndDateSplits = eventEndDate.toDateString().split(" ");

            const eventStartDateFormatted = DateFormatedByLanguage(
              item.eventStartDate,
              languageId
            );
            const eventEndDateFormatted = DateFormatedByLanguage(
              item.eventEndDate,
              languageId
            );
            return (
              <Card
                elevation={0}
                key={item.uniqueName}
                sx={{
                  maxWidth: 240,
                  height: "100%",
                  marginX: "2px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: "inherit",
                }}
                // onClick={() => navigate(`Events/${item.uniqueName}`)}
              >
                <Box
                  sx={{
                    transition: "0.5s",
                    "&:hover": {
                      transform: "Scale(1.1)",
                      zIndex: 2,
                    },
                  }}
                  onClick={() => navigate(`Events/${item.uniqueName}`)}
                >
                  <CardActionArea>
                    <TryToAttendCardMedia
                      mainImageFullUrl={item.mainImageFullUrl}
                      eventStartDateSplits={eventStartDateSplits}
                      eventEndDateSplits={eventEndDateSplits}
                    />
                    <CardContent sx={{ height: 60 }}>
                      <Typography gutterBottom variant="h6" component="div">
                        {item.title.substring(0, 17)} ...
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <History />
                    <Typography
                      sx={{ marginX: "2%", fontSize: 10 }}
                      variant="body2"
                    >
                      {`${eventStartDateFormatted} - ${eventEndDateFormatted}`}
                    </Typography>
                  </CardActions>
                </Box>
                <ButtonBase
                  sx={{ display: "inline" }}
                  onClick={() => addToCalanderHandler(item)}
                >
                  <CardActions>
                    <CalendarMonth />
                    <Typography sx={{ marginX: "2%" }} variant="body2">
                      {t("addtocalanar")}
                    </Typography>
                  </CardActions>
                </ButtonBase>
              </Card>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default TryToAttendSection;
