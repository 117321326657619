import { Grid, Theme, createTheme, styled } from "@mui/material";
import { goldenTheme, blueTheme } from "../../theme/theme";
import { COLORS } from "../constants";

export const emailValidation = /\S+@\S+\.\S+/;

export const DateFormatedByLanguage = (
  orginalDate: string,
  languageId: 1 | 2
) => {
  const lang = languageId === 1 ? "en-US" : "ar";
  const dateConstructor = new Date(orginalDate);
  const result =
  dateConstructor &&
    new Intl.DateTimeFormat(lang, {
      dateStyle: "long",
    }).format(dateConstructor);
    return result
};

export function getThemeByName(theme: string): Theme {
  return themeMap[theme];
}

export function changeThemeProps(theme: Theme): Theme {
  const customTheme = createTheme(theme);
  return customTheme;
}
const themeMap: { [key: string]: Theme } = {
  goldenTheme,
  blueTheme,
};

export const StyledGrid = styled(Grid)(() => ({
  backgroundImage: `linear-gradient(to bottom,${COLORS.secondary} 15%, ${COLORS.formPrimary})`,
  border: `1px solid ${COLORS.formPrimary}`,
  borderRadius: `25px 25px 0 0`,
  // marginTop: 8,
  width: "100%",
  padding: 9,
}));

export const StyledBorderedGrid = styled(StyledGrid)(() => ({
  borderRadius: `20px 20px 0 0`,
  border: `2px solid ${COLORS.black}`,
  backgroundImage: `linear-gradient(to bottom,${COLORS.secondary} 50%, ${COLORS.formPrimary})`,
  padding: 9,
}));

export const getAppsettings= async()=>{
  
  const json =await fetch('assets/appsettings/appsettings.json'
  ,{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  )
    .then(function(response){
      return response.json();
    })
    return json;
}
