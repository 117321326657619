import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { WEATHER } from "../queryKeyes";

export type WeatherResponse = {
  coord: {
    lon: number;
    lat: number;
  };
  weather: {
    id: number;
    main: string;
    description: string;
    icon: string;
  }[];
  base: string;
  main: {
    temp: number;
    feels_like: number;
    temp_min: number;
    temp_max: number;
    pressure: number;
    humidity: number;
  };
  visibility: number;
  wind: {
    speed: number;
    deg: number;
  };
  clouds: {
    all: number;
  };
  dt: number;
  sys: {
    type: number;
    id: number;
    country: string;
    sunrise: number;
    sunset: number;
  };
  timezone: number;
  id: number;
  name: string;
  cod: number;
};

const weatherFetching = async (
  latitude: number,
  longitude: number
): Promise<WeatherResponse> => {
  const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${process.env.REACT_APP_WEATHER_API_KEY}`;

  const response = await axios.get(url);
  return response.data;
};
const useWeather = (latitude: number, longitude: number) => {
  return useQuery<WeatherResponse, Error>([WEATHER, latitude, longitude], () =>
    weatherFetching(latitude, longitude)
  );
};

export default useWeather;
