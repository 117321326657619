import { Box, Skeleton } from "@mui/material";
import React from "react";

interface MenuSkeletonProp {}

const MenuSkeleton: React.FC<MenuSkeletonProp> = (props) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} mx={'5%'} p={1}>
      <Skeleton variant="rectangular" width={"90%"} height={45} />
    </Box>
  );
};

export default MenuSkeleton;
