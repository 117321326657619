import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { IMAGEGALLERYSEARCH } from "../../../shared/queryKeyes";
import { VideoGalleryPage } from "../types";

export const VIDEO_GALLERYPAGE_SIZE = 12;
const videoGallerySearchFetching = async (
  pageParam = 1,
  langId: number,
  keyword: string
): Promise<VideoGalleryPage> => {
  const response = await API.get(
    `MediaGallery/Search?categoryUniqueName=video.gallery&pageSize=${VIDEO_GALLERYPAGE_SIZE}&pageNumber=${pageParam}
    &langId=${langId}&keyword=${keyword}`
  );
  return response.data;
};
const useVideoGallerySearch = (pageNumber: number, langId: number, keyword: string) => {
  return useQuery<VideoGalleryPage, Error>([IMAGEGALLERYSEARCH, pageNumber, langId], () =>
  videoGallerySearchFetching(pageNumber, langId, keyword)
  );
};

export default useVideoGallerySearch;
