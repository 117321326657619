const useGuidedTourCookie = () => {
    const setGuidedTourCookie = (): void => {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
      const expires = "expires=" + expirationDate.toUTCString();
      document.cookie = "guided-tour" + "=" + "true" + "; " + expires;
    };
  
    const getGuidedTourCookieValue = (): string | null => {
      const cookies = document.cookie.split("; ");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        const cookieName = cookie[0];
        const cookieValue = cookie[1];
        if (cookieName === "guided-tour") {
          return cookieValue;
        }
      }
      return null; // Cookie not found
    };
    return { getGuidedTourCookieValue, setGuidedTourCookie };
  };
  
  export default useGuidedTourCookie;
  