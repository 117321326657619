import { Box, Button, ButtonGroup, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AdvanceSearchComponent from "./components/AdvanceSearchComponent";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import useAdvanceSearch, {
  ADVANCE_SEARCH_PAGE_SIZE,
} from "./hooks/useAdvanceSearch";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import NoMatch from "../noMatch/NoMatch";
import { AdvanceSearchStateVM } from "./types";
import { useParams } from "react-router-dom";
import AdvanceSearchContaier from "./components/AdvanceSearchContaier";

interface SearchProp {}

const Search: React.FC<SearchProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { searchvalue } = useParams();
  const languageId = useLanguageID();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [advanceSearchFormState, setAdvanceSearchFormState] =
    React.useState<AdvanceSearchStateVM>({
      key: searchvalue ?? "",
      CategoryID: -1,
    });
  const { data, isLoading, isError, refetch } = useAdvanceSearch(
    pageNumber,
    languageId,
    advanceSearchFormState.CategoryID,
    advanceSearchFormState.key
  );
  const refetchHandler = () => {
    refetch();
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <NoMatch />;
  }
  const { searchResult, totalCount } = data;

  const pageCount = Math.ceil(totalCount / ADVANCE_SEARCH_PAGE_SIZE);

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  const getPageButtonRange = () => {
    let firstPage = Math.max(1, pageNumber - 2);
    let lastPage = Math.min(pageCount, pageNumber + 2);

    if (firstPage === 1) {
      lastPage = Math.min(pageCount, 5);
    }

    if (lastPage === pageCount) {
      firstPage = Math.max(1, pageCount - 4);
    }

    return Array.from(
      { length: lastPage - firstPage + 1 },
      (_, i) => firstPage + i
    );
  };
  return (
    <Box marginX={"8%"} marginTop={5}>
      <Stack spacing={5} mb={1}>
        <Typography
          variant="h3"
          fontWeight={"bold"}
          sx={{
            display: "block",
            marginTop: 2,
            marginBottom: 3,
          }}
          color={"primary"}
        >
          {t("advance_search")}
        </Typography>
        <Typography
          variant="h4"
          fontWeight={"500"}
          sx={{
            display: "block",
            marginTop: 2,
            marginBottom: 3,
          }}
        >
          {t("filterBy")}
        </Typography>
      </Stack>
      <AdvanceSearchComponent
        advanceSearchFormState={advanceSearchFormState}
        setAdvanceSearchFormState={setAdvanceSearchFormState}
        refetchHandler={refetchHandler}
      />
      <AdvanceSearchContaier result={searchResult}/>

      {searchResult && searchResult.length > 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            marginTop={2}
          >
            <ButtonGroup aria-label="outlined button group">
              <Button
                onClick={() => handlePageChange(1)}
                disabled={pageNumber === 1}
                variant="text"
              >
                {t("first")}
              </Button>
              <Button
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
                variant="text"
              >
                {t("previous")}
              </Button>
            </ButtonGroup>

            <Stack alignItems={"center"}>
              <Typography letterSpacing={1} color={"primary"}>
                {`${t("showing")} ${
                  ADVANCE_SEARCH_PAGE_SIZE < totalCount
                    ? ADVANCE_SEARCH_PAGE_SIZE
                    : totalCount
                } ${t("of")} ${totalCount} ${t("results")}`}
              </Typography>
              <Box marginX={0}>
                {getPageButtonRange().map((page) => (
                  <Button
                    // sx={{ marginX: 1 }}
                    key={page}
                    onClick={() => handlePageChange(page)}
                    disabled={pageNumber === page}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </Stack>

            <ButtonGroup aria-label="outlined button group">
              <Button
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber === pageCount}
                variant="text"
              >
                {t("next")}
              </Button>
              <Button
                onClick={() => handlePageChange(pageCount)}
                disabled={pageNumber === pageCount}
                variant="text"
              >
                {t("last")}
              </Button>
            </ButtonGroup>
          </Box>
        ) : (
          <Typography textAlign={"center"} variant="h5" color={"primary"}>
            {t("zeroresult")}
          </Typography>
        )}
    </Box>
  );
};

export default Search;
