import React from "react";
import { COLORS } from "../../../constants";
import { Box, Divider, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { golden, red } from "../../../../theme/theme";
interface HeaderEmergencySectionProp {}
const StyledEmergencyBox = styled(Box)(({ theme }) => ({
  "&:hover": { cursor: "pointer" },
  // backgroundColor: theme.palette.secondary.main,
  backgroundColor:
    theme.palette.primary.main == golden
      ? red
      : theme.palette.secondary.main,
  // backgroundColor: COLORS.red,
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  // color: theme.palette.primary.main == golden ? golden : COLORS.white,
  color: COLORS.white,
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main == golden ? golden : COLORS.white,
  backgroundColor: COLORS.white,
  marginTop: "1px",
  borderWidth: "1px",
}));
const HeaderEmergencySection: React.FC<HeaderEmergencySectionProp> = (
  props
) => {
  const navigate = useNavigate();
  return (
    <StyledEmergencyBox
      mx={"2%"}
      sx={{ padding: 2 }}
      onClick={() => navigate("/GenericContent/emergency.hotline")}
    >
      <StyledTypography
        variant="h6"
        // component="h2"
        sx={{
          fontWeight: "bold",
          // color: COLORS.white,
        }}
      >
        SOS
      </StyledTypography>
      {/* <Divider sx={{ marginTop: "1px", backgroundColor: COLORS.white }} /> */}
      <StyledDivider />
    </StyledEmergencyBox>
  );
};

export default HeaderEmergencySection;
