import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Container, Button } from "@mui/material";
import { COLORS, homePageItem, menuPages } from "../../constants";
import useThemeChanger from "../../../pages/home/hooks/useThemeChanger";
import { useTranslation } from "react-i18next";
import VerticalMenu from "./pureComponents/VerticalMenu";
import HorizantalMenu from "./pureComponents/HorizantalMenu";
import { useLanguageID } from "../../hooks/useLanguageID";
import useMenu from "../../hooks/useMenu";
import MenuSkeleton from "../MenuSkeleton";

interface NavigationBarProp {}
const NavigationBar: React.FC<NavigationBarProp> = (props) => {
  const { changeLanguage } = useThemeChanger();
  const { t } = useTranslation();
  const languageId = useLanguageID();
  
  const { data, isError, error, isLoading } = useMenu(languageId);
  if (isLoading) {
    return <MenuSkeleton />;
  }

  if (isError) {
    return null;
  }
  // const existingItem = data.find((item) => item.title === "home");

  // if (!existingItem) {
  //   data.unshift(homePageItem);
  // }
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: COLORS.secondary }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <VerticalMenu menuPages={data} />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              marginInline: { xs: "3%" },
            }}
          >
            <HorizantalMenu menuPages={data} />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              variant="outlined"
              sx={{ color: COLORS.black, borderColor: COLORS.black,fontFamily:'GEDinarTwo-MediumItalic' }}
              onClick={changeLanguage}
            >
              {t("language")}
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavigationBar;
