import React from "react";
import { Button, InputBase, Stack, Typography, useTheme } from "@mui/material";

import { COLORS } from "../../../../shared/constants";
import { useTranslation } from "react-i18next";
import useSubscribe from "../../hooks/useSubscribe";
import { toast } from "react-toastify";
import { useLanguageID } from "../../../../shared/hooks/useLanguageID";
import { emailValidation } from "../../../../shared/utils";

const AppLogo = require("../../../../assets/images/logoVertical.png");

interface FooterPrimaryNewsLetterProp {}
const FooterPrimaryNewsLetter: React.FC<FooterPrimaryNewsLetterProp> = (
  props
) => {
  const [emailState, setEmailState] = React.useState("");
  const { submit, subscribeData } = useSubscribe();
  const { t } = useTranslation();
  const langId = useLanguageID();
  const theme = useTheme();

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmailState(event.target.value);
  };
  const onSubmit = async () => {
    await submit({ emailAddress: emailState });
    setEmailState("");
  };
  React.useEffect(() => {
    if (langId === 1) {
      toast(subscribeData?.messageEn, {
        type:
          subscribeData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast(subscribeData?.messageAr, {
        type:
          subscribeData?.status.toLowerCase() === "success"
            ? "success"
            : "warning",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [subscribeData?.status]);
  const validateEmail = emailValidation.test(emailState)
  return (
    <Stack gap={6}>
      <img src={AppLogo} alt="Logo" style={{ maxWidth: 174,height:'auto' }} />
      <Stack display={"flex"} gap={2} id='newsletter' sx={{display:'none'}}>
        <Typography variant={"subtitle2"}>{t("regforlastupd")}</Typography>
        <Stack direction={"row"} gap={2}>
          <InputBase
            type="email"
            value={emailState}
            onChange={onChangeHandler}
            sx={{
              backgroundColor: COLORS.white,
              padding: "0 10px",
              borderRadius: 2,
              width: "70%",
            }}
          />
          <Button
            sx={{ color: COLORS.white }}
            variant="contained"
            disabled={!validateEmail}
            onClick={onSubmit}
          >
            {t("send")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FooterPrimaryNewsLetter;
