import React from "react";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { NavLink } from "react-router-dom";

import { MenuDM } from "../../../types";
import NGuardNavLink from "./NGuardNavLink";
import { useTranslation } from "react-i18next";
import { COLORS, customPages } from "../../../constants";
interface HorizantalMenuProp {
  menuPages: MenuDM[];
}
const HorizantalMenu: React.FC<HorizantalMenuProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [menus, setMenus] = React.useState([""]);

  const handleAddToMenu = (contentUniquename: string) => {
    if (!menus.includes(contentUniquename)) {
      setMenus(["", contentUniquename]);
    }
  };
  const handleRemovesFromMenu = () => {
    setMenus([""]);
  };
  return (
    <Box
      id="main-menu"
      display={"flex"}
      width={"100%"}
      position={"relative"}
      zIndex={1301}
      onMouseLeave={handleRemovesFromMenu}
    >
      <Box
        key={"home"}
        sx={{ marginInline: "1.5%" }}
        display={"flex"}
        alignItems={"center"}
      >
        <NGuardNavLink to={""} title={t("home")} />
      </Box>
      {props.menuPages.map((page) => {
        if (
          (page.contentUniquename === "" || !page.contentUniquename) &&
          page.title !== "home"
        ) {
          var splitVirtualPath = page.virtualPath.split("/");
          page.contentUniquename =
            splitVirtualPath[splitVirtualPath.length - 1];
        }
        return (
          <Box
            key={page.linkFolder}
            sx={{ marginInline: "1.5%" }}
            display={"flex"}
            alignItems={"center"}
          >
            {page.children && page.children.length > 0 ? (
              <FormControl sx={{ m: 0, minWidth: 100 }}>
                <Select
                  value={page.contentUniquename ?? ""}
                  open={menus.includes(page.contentUniquename!)}
                  disableUnderline
                  variant="standard"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onClick={() => handleAddToMenu(page.contentUniquename!)}
                  onClose={handleRemovesFromMenu}
                  onMouseEnter={() => handleAddToMenu(page.contentUniquename!)}
                  onMouseLeave={handleRemovesFromMenu}
                >
                  <MenuItem
                    value={page.contentUniquename ?? ""}
                    dir={theme.direction}
                    sx={{
                      display: "none",
                      borderBottom: `2px solid #ddd`,
                      paddingBottom: 1,
                      marginInlineEnd: 2,
                    }}
                    onClick={handleRemovesFromMenu}
                  >
                    <NavLink
                      style={{
                        color: "black",
                        display: "block",
                        textDecoration: "none",
                      }}
                      to={"#"}
                    >
                      {page.title}
                    </NavLink>
                  </MenuItem>
                  <Box minWidth={250} onMouseLeave={handleRemovesFromMenu}>
                    {page.children.map((subPage, index) => {
                      //#region contentUniquename
                      if (
                        subPage.contentUniquename === "" ||
                        !subPage.contentUniquename
                      ) {
                        var splitVirtualPath = page.virtualPath.split("/");
                        subPage.contentUniquename =
                          splitVirtualPath[splitVirtualPath.length - 1];
                      }
                      const existingIncustom = customPages.find(
                        (item) => item === subPage.contentUniquename
                      );
                      const borderBottom =
                        index === page.children.length - 1
                          ? ""
                          : `2px solid #ddd`;
                      //#endregion
                      return (
                        <Box
                          key={subPage.contentUniquename}
                          sx={{ direction: theme.direction }}
                        >
                          <MenuItem
                            key={subPage.contentUniquename}
                            value={subPage.contentUniquename ?? ""}
                            onClick={handleRemovesFromMenu}
                          >
                            <NGuardNavLink
                              bannerImage={subPage.mainImageFullUrl}
                              to={
                                existingIncustom
                                  ? subPage.contentUniquename
                                  : `GenericContent/${subPage.contentUniquename}`
                              }
                              title={subPage.title}
                              borderBottom={borderBottom}
                              paddingBottom={8}
                            />
                          </MenuItem>
                        </Box>
                      );
                    })}
                  </Box>
                </Select>
              </FormControl>
            ) : (
              <NGuardNavLink
                bannerImage={page.mainImageFullUrl}
                to={
                  customPages.find((item) => item === page.contentUniquename)
                    ? page.contentUniquename!
                    : `GenericContent/${page.contentUniquename}`
                }
                title={t(page.title)}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default HorizantalMenu;
