import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { NewsLatestDM } from "../types";
import { NEWSLATEST } from "../../../shared/queryKeyes";

  const newsLatestFetching = async (languageId: number): Promise<NewsLatestDM[]> => {
    const response = await API.get(`News/Latest?count=5&langId=${languageId}`);
    return response.data;
  };
  const useNewsLatest = (languageId: number) => {
    return useQuery<NewsLatestDM[],Error>([NEWSLATEST, languageId], () => newsLatestFetching(languageId));
  };

  export default useNewsLatest;