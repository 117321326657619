import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { CATEGORIES } from "../../../shared/queryKeyes";
import { CategoryDM } from "../../homeIndex/types";

  const categoryFetching = async (languageId: number): Promise<CategoryDM[]> => {
    const response = await API.get(`News/Categories?langId=${languageId}`);
    return response.data;
  };
  const useCategory = (languageId: number) => {
    return useQuery<CategoryDM[],Error>([CATEGORIES, languageId], () => categoryFetching(languageId));
  };

  export default useCategory;