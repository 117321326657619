import styled from "@emotion/styled";
import { WbSunny } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { COLORS } from "../../../constants";
import useGeolocation from "../../../hooks/useGeolocation";
import useWeather from "../../../hooks/useWeather";
import WeatherIcon from "../../weatherIcon/WeatherIcon";
import { useLanguageID } from "../../../hooks/useLanguageID";
interface HeaderWeatherSectionProp {}
const StyledWeatherBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginInline: "1%",
}));
const HeaderWeatherSection: React.FC<HeaderWeatherSectionProp> = (props) => {
  const langId = useLanguageID();
  const [latitude, longitude] = useGeolocation();
  const { data, isError, isLoading } = useWeather(latitude, longitude);
  if (isLoading) {
    return <div></div>;
  }
  if (isError) {
    return (
      <StyledWeatherBox sx={{ display: { xs: "none", md: "flex" } }}>
        <WbSunny sx={{ color: COLORS.white }} />
        <Typography
          sx={{ position: "relative" }}
          variant="subtitle1"
          component="h2"
          color={COLORS.white}
        >
          36
          <Typography
            sx={{ position: "absolute", top: 0 }}
            variant="subtitle2"
            component="span"
            color={COLORS.white}
          >
            &deg;C
          </Typography>
        </Typography>
      </StyledWeatherBox>
    );
  }
  return (
    <>
      {langId === 1 ? (
        <StyledWeatherBox sx={{ display: { xs: "none", md: "flex" } }}>
        <WeatherIcon iconCode={data?.weather?.[0]?.icon ?? "10n"} />
        <Typography
          sx={{ position: "relative" }}
          variant="subtitle1"
          component="h2"
          color={COLORS.white}
        >
          {Math.floor(data.main.temp)}
          <Typography
            sx={{ position: "absolute", top: 0 }}
            variant="subtitle2"
            component="span"
            color={COLORS.white}
          >
            &deg;C
          </Typography>
        </Typography>
        </StyledWeatherBox>
      ) : (
        <StyledWeatherBox sx={{ display: { xs: "none", md: "flex" } }}>
        
        <Typography
          sx={{ position: "relative",marginInlineStart:2 }}
          variant="subtitle1"
          component="h2"
          color={COLORS.white}
        >
          
          <Typography
            sx={{ position: "absolute", top: 0,left:20 }}
            variant="subtitle2"
            component="span"
            color={COLORS.white}
          >
            &deg;C
          </Typography>
          {Math.floor(data.main.temp)}
        </Typography>
        <WeatherIcon iconCode={data?.weather?.[0]?.icon ?? "10n"} />
        </StyledWeatherBox>
      )}
    </>
  );
};

export default HeaderWeatherSection;
