import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import {
  BrochureDM,
  ImagegalleryDM,
  MagazineDM,
  VideoGalleryDM,
} from "../types";
import {
  BANNERBROCHURE,
  BANNERMAGAZINE,
  IMAGEGALLERY,
  VIDEOGALLERY,
} from "../../../shared/queryKeyes";
import useGenericContent from "../../../shared/hooks/useGenericContent";

const imagegalleryFetching = async (
  languageId: number
): Promise<ImagegalleryDM[]> => {
  const response = await API.get(
    `MediaGallery/Latest?count=5&categoryUniqueName=image.gallery&langId=${languageId}`
  );
  return response.data;
};
const videoGalleryFetching = async (
  languageId: number
): Promise<VideoGalleryDM[]> => {
  const response = await API.get(
    `MediaGallery/Latest?count=5&categoryUniqueName=video.gallery&langId=${languageId}`
  );
  return response.data;
};
const magazineFetching = async (languageId: number): Promise<MagazineDM[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=magazine&langId=${languageId}`
  );
  return response.data;
};
const brochureFetching = async (languageId: number): Promise<BrochureDM[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=brochure&langId=${languageId}`
  );
  return response.data;
};

const useMediaCenter = (languageId: number) => {
  const imagegalleryRQR = useQuery<ImagegalleryDM[], Error>(
    [IMAGEGALLERY, languageId],
    () => imagegalleryFetching(languageId)
  );
  const videogalleryRQR = useQuery<VideoGalleryDM[], Error>(
    [VIDEOGALLERY, languageId],
    () => videoGalleryFetching(languageId)
  );
  const magazineRQR = useQuery<MagazineDM[], Error>(
    [BANNERMAGAZINE, languageId],
    () => magazineFetching(languageId)
  );

  const brochureRQR = useQuery<BrochureDM[]>([BANNERBROCHURE, languageId], () =>
    brochureFetching(languageId)
  );

  const imageGallerySummaryRQR = useGenericContent(languageId, "image.gallery");
  const videoGallerySummaryRQR = useGenericContent(languageId, "video.gallery");

  const isLoading =
    imagegalleryRQR.isLoading ||
    videogalleryRQR.isLoading ||
    magazineRQR.isLoading ||
    brochureRQR.isLoading;
  const isError =
    imagegalleryRQR.isError ||
    videogalleryRQR.isError ||
    magazineRQR.isError ||
    brochureRQR.isError;
  const error =
    imagegalleryRQR.error ??
    videogalleryRQR.error ??
    magazineRQR.error ??
    brochureRQR.error;
  const mediaCenterData = [
    imagegalleryRQR.data,
    videogalleryRQR.data,
    magazineRQR.data,
    brochureRQR.data,
  ];
  return {
    imagegalleryRQR,
    videogalleryRQR,
    magazineRQR,
    brochureRQR,
    imageGallerySummaryRQR,
    videoGallerySummaryRQR,
  };
};

export default useMediaCenter;
