import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { MessageDM } from "../types";
import { MESSAGES } from "../../../shared/queryKeyes";

  const messagesFetching = async (languageId: number): Promise<MessageDM[]> => {
    const response = await API.get(`GenericContent/GenericContentsByCategory?categoryUniqueName=home.page.messages&langId=${languageId}`);
    return response.data;
  };
  const useMessages = (languageId: number) => {
    return useQuery<MessageDM[],Error>([MESSAGES, languageId], () => messagesFetching(languageId));
  };

  export default useMessages;