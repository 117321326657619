import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { SEARCHCATEGORIES } from "../../../shared/queryKeyes";
import { SearchCategoryDM } from "../../homeIndex/types";

  const searchCategoryFetching = async (languageId: number): Promise<SearchCategoryDM[]> => {
    const response = await API.get(`Search/GetSearchCategories?lang=${languageId}`);
    return response.data;
  };
  const useSearchCategory = (languageId: number) => {
    return useQuery<SearchCategoryDM[],Error>([SEARCHCATEGORIES, languageId], () => searchCategoryFetching(languageId));
  };

  export default useSearchCategory;