import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { NEWSBYUNIQUENAME } from "../../../shared/queryKeyes";
import { EventDM } from "../../homeIndex/types";

const eventByUniqueNameFetching = async (
  languageId: number,
  uniqueName: string
): Promise<EventDM> => {
  const response = await API.get(
    `Events/ContentByUniqueName?uniqueName=${uniqueName}&langId=${languageId}`
  );
  return response.data;
};
const useEventByUniqueName = (languageId: number, uniqueName: string) => {
  return useQuery<EventDM, Error>(
    [NEWSBYUNIQUENAME, languageId, uniqueName],
    () => eventByUniqueNameFetching(languageId, uniqueName)
  );
};

export default useEventByUniqueName;
