import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../../client/api";
import { SENDTOFRIEND } from "../queryKeyes";
export type SendToFreindData = {
  sender_Name: string;
  sender_Email: string;
  receiver_Name: string;
  receiver_Email: string;
  urlToSend: string;
  source: string;
  languageCode: string;
};

type SendToFreindResponse = {
  status: string;
  messageEn: string;
  messageAr: string;
};

export const sendToFriendPost = async (
  data: SendToFreindData
): Promise<SendToFreindResponse> => {
  const response = await API.post(`SendToFriend`, {
    sender_Name: data.sender_Name,
    sender_Email: data.sender_Email,
    receiver_Name: data.receiver_Name,
    receiver_Email: data.receiver_Email,
    urlToSend: data.urlToSend,
    source: data.source,
    languageCode: data.languageCode,
  });
  return response.data;
};
const useSendToFriendMutation = () => {
  const queryClient = useQueryClient();
  const { data, isError, error, isLoading, mutate } = useMutation<
    SendToFreindResponse,
    Error,
    SendToFreindData
  >(sendToFriendPost);

  const submit = async (dataPassed: SendToFreindData) => {
    await mutate(dataPassed);
    await queryClient.refetchQueries([SENDTOFRIEND]);
  };
  return {
    submit,
    isPollsMutationError: isError,
    pollsMutationError: error,
    pollsMutationIsLoading: isLoading,
    sendtoFriendMutationData: data,
  };
};

export default useSendToFriendMutation;
