import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { EventDM } from "../types";
import { EVENTSLATEST } from "../../../shared/queryKeyes";

  const eventsLatestFetching = async (languageId: number): Promise<EventDM[]> => {
    const response = await API.get(`Events/Latest?count=4&langId=${languageId}`);
    return response.data;
  };
  const useEventsLatest = (languageId: number) => {
    return useQuery<EventDM[],Error>([EVENTSLATEST, languageId], () => eventsLatestFetching(languageId));
  };

  export default useEventsLatest;