import { createTheme, PaletteColorOptions } from "@mui/material";
import { COLORS } from "../shared/constants";
type Color = PaletteColorOptions

export const golden = '#b5a03a';
export const blue = '#005A82';
export const red = '#c8122e';
export const green = '#00843D';
export const gray = '#ececec';


// Create Colors
export const primaryGolden: Color = {
  main: golden
}
export const secondaryGolden: Color = {
    main: gray,
}

export const primaryBlue: Color = {
  main: blue
}
export const secondaryBlue: Color = {
  main: golden,
}
export const primaryGreen: Color = {
  main: green
}
export const secondaryGreen: Color = {
  main: golden,
}
export const primaryRed: Color = {
  main: red
}
export const secondaryRed: Color = {
  main: golden,
}
export const info: Color = {
  main: COLORS.black,
}


// Create Palette
export const goldPalette = {
  primary : primaryGolden,
  secondary : secondaryGolden,
  info,
  
}
export const bluePalette = {
  primary : primaryBlue,
  secondary : secondaryBlue,
  info
  
}
export const greenPalette = {
  primary : primaryGreen,
  secondary : secondaryBlue,
  info
  
}
export const redPalette = {
  primary : primaryRed,
  secondary : secondaryGreen,
  info
  
}



// Create Themes
export const goldenTheme = createTheme({
    palette: goldPalette,
    typography: {
      fontSize: 14,
      fontFamily: 'Roboto',
      // fontFamily: 'GEDinarTwo-MediumItalic',
    },
    // direction: 'ltr'
})
export const blueTheme = createTheme({
  palette: bluePalette,
  typography: {
    fontSize: 14,
  },
  
})
export const contrastTheme = createTheme({
  palette: {
    primary: {
      main: '#666',
      
    },
    secondary: {
      main: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  
  // typography: {
  //   fontSize: 14,
  //   fontFamily: 'Roboto',
  //   allVariants:{
  //     color:'#f00'
  //   },
  //   // fontFamily: 'GEDinarTwo-MediumItalic',
  // },
});