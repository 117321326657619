import { MenuDM } from "../types";

export const COLORS = {
  primary: "#b5a03a",
  secondary: "#ececec",
  black: "#231f20",
  white: "#ffffff",
  red: "#BB2025",
  opacityGray: "#ececec61",
  opacityBlack: "#0e0e0ed6",
  formPrimary: "#2b2800",
  formSecondary:  "#fff9e8b3"
};
export const THEMES = {
  goldenTheme: {
    primary: "#b5a03a",
    secondary: "#ececec",
    black: "#231f20",
    white: "#ffffff",
    red: "#BB2025",
  },
};
export const AppBarIconMarginInline = "2%";
export const homePageItem: MenuDM = {
  title: "home",
    contentCategoryUniqueName: "",
    contentUniquename: "",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
}
export const menuPages: MenuDM[] = [
  {
    title: "home",
    contentCategoryUniqueName: "",
    contentUniquename: "",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
  },
  {
    title: "aboutcicp",
    contentCategoryUniqueName: "",
    contentUniquename: "AboutCICP",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
  },
  {
    title: "cicpunits",
    contentCategoryUniqueName: "",
    contentUniquename: "CICPUnits",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
  },
  {
    title: "cicpservices",
    contentCategoryUniqueName: "",
    contentUniquename: "CICPServices",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
  },
  {
    title: "evetns",
    contentCategoryUniqueName: "",
    contentUniquename: "Events",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
  },
  {
    title: "mediacenter",
    contentCategoryUniqueName: "",
    contentUniquename: "MediaCenter",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: Array<MenuDM>({
      title: "newsarticles",
      contentCategoryUniqueName: "",
      contentUniquename: "News",
      virtualPath: "",
      linkFolder: "",
      description: "",
      mainImageFullUrl: "",
      children: [],
      sequence: 0,
      path: [""],
    }),
    path: [""],
  },
  {
    title: "contactcicp",
    contentCategoryUniqueName: "",
    contentUniquename: "ContactCICP",
    virtualPath: "",
    linkFolder: "",
    description: "",
    mainImageFullUrl: "",
    sequence: 0,
    children: [],
    path: [""],
  },
];

export const customPages = ['events','news','image.gallery','video.gallery','sitemap','polls','suggestions.complaints']
