import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { IMAGEGALLERYSEARCH } from "../../../shared/queryKeyes";
import { ImageGalleryPage } from "../types";

export const IMAGE_GALLERYPAGE_SIZE = 12;
const imageGallerySearchFetching = async (
  pageParam = 1,
  langId: number,
  keyword: string
): Promise<ImageGalleryPage> => {
  const response = await API.get(
    `MediaGallery/Search?categoryUniqueName=image.gallery&pageSize=${IMAGE_GALLERYPAGE_SIZE}&pageNumber=${pageParam}
    &langId=${langId}&keyword=${keyword}`
  );
  return response.data;
};
const useImgageGallerySearch = (pageNumber: number, langId: number, keyword: string) => {
  return useQuery<ImageGalleryPage, Error>([IMAGEGALLERYSEARCH, pageNumber, langId], () =>
    imageGallerySearchFetching(pageNumber, langId, keyword)
  );
};

export default useImgageGallerySearch;
