import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { WelcomPopUpDM } from "../types";
import { WELCOMPOPUP } from "../../../shared/queryKeyes";

const welcomPopupFetching = async (
  languageId: number
): Promise<WelcomPopUpDM[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=welcome.popup&langId=${languageId}`
  );
  return response.data;
};
const useWelcomPopup = (languageId: number) => {
  return useQuery<WelcomPopUpDM[], Error>([WELCOMPOPUP, languageId], () =>
  welcomPopupFetching(languageId)
  );
};

export default useWelcomPopup;
