import React from "react";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import useMenu from "../../shared/hooks/useMenu";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import SiteMapSearchForm from "./components/SiteMapSearchForm";

import { MenuDM } from "../../shared/types";
import SiteMapContainer from "./components/SiteMapContainer";
import SubPageTitle from "../../shared/components/typographies/SubPageTitle";
import FormTitle from "../../shared/components/typographies/FormTitle";
import HomeTopBannersIcons from "../homeIndex/components/pureComponents/HomeTopBannersIcons";

interface SiteMapProp {}
const SiteMap: React.FC<SiteMapProp> = (props) => {
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isError, error, isLoading, isFetched, refetch } =
    useMenu(languageId);
  const [key, setKey] = React.useState("");
  const [searchMenus, setSearchMenu] = React.useState(data ?? []);

  React.useEffect(() => {
    setSearchMenu(data ?? []);
  }, [isFetched, languageId]);

  const searchHandler = () => {
    const searchedNews = data?.filter((s) =>
      s.title.toLowerCase().includes(key.toLowerCase())
    );
    if (searchedNews && searchedNews.length > 0) {
      setSearchMenu(searchedNews);
    }
  };
  const resetHandler = () => {
    setSearchMenu(data ?? []);
  };
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKey(event.target.value);
  };

  if (isLoading) {
    return <CustomSkeleton />;
  }
  if (isError) {
    return null;
  }

  let mainsitenavigation: MenuDM[] = [];
  let sitequicklinks: MenuDM[] = [];
  searchMenus.forEach((searchmenuItem) => {
    if (searchmenuItem.children && searchmenuItem.children.length > 0) {
      mainsitenavigation.push(searchmenuItem);
    } else {
      sitequicklinks.push(searchmenuItem);
    }
  });

  return (
    <Box marginX={"8%"} marginTop={5}>
      <Box>
        <Stack spacing={5} mb={1}>
          <SubPageTitle title={t("sitemap")} />
          <FormTitle title={t("filterBy")} />
        </Stack>
      </Box>

      <SiteMapSearchForm
        onChangeHandler={onChangeHandler}
        searchHandler={searchHandler}
        resetHandler={resetHandler}
      />

      <Box mt={5}>
        <SiteMapContainer
          mainsitenavigation={mainsitenavigation}
          sitequicklinks={sitequicklinks}
        />
      </Box>
    </Box>
  );
};

export default SiteMap;
