import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/home/Home";
import NoMatch from "../pages/noMatch/NoMatch";
import HomeIndex from "../pages/homeIndex/HomeIndex";
import useLoginMutation from "../shared/hooks/useLoginMutation";
import NewsList from "../pages/newsList/NewsList";
import NewsDetails from "../pages/newsDetails/NewsDetails";
import GenericContent from "../shared/components/genericContent/GenericContent";
import CompRegAndSigAuth from "../pages/companyRegistrationAndSignatureAuthorazationForm/CompRegAndSigAuth";
import IntroductionStatment from "../pages/introductionStatment/IntroductionStatment";
import CompanyPledge from "../pages/companyPledge/CompanyPledge";
import EventsList from "../pages/eventsList/EventsList";
import ScrollToTop from "../shared/components/ScrollToTop";
import ImageGallery from "../pages/imageGallery/ImageGallery";
import VideoGallery from "../pages/videoGallery/VideoGallery";
import EventDetails from "../pages/eventDetails/EventDetails";
import SiteMap from "../pages/siteMap/SiteMap";
import Search from "../pages/search/Search";
import Polls from "../pages/polls/Polls";
import Complaints from "../pages/complaints/Complaints";

export default function RouterProv() {
  const { login} = useLoginMutation();
  React.useEffect(() => {
    login();
  }, []);
  return (
    <ScrollToTop>
    <Routes>
      <Route path="/" element={<Home />}>
        <Route index element={<HomeIndex />} />

        {/* <Route path="CompRegAndSigAuth" element={<CompRegAndSigAuth />} /> */}
        {/* <Route path="IntroductionStatment" element={<IntroductionStatment />} /> */}
        {/* <Route path="CompanyPledge" element={<CompanyPledge />} /> */}
        
        <Route path="News" element={<NewsList />} />
        <Route path="Events" element={<EventsList />} />
        <Route path="News/:uniquename" element={<NewsDetails />} />
        <Route path="Events/:uniquename" element={<EventDetails />} />
        <Route path="GenericContent/:uniquename" element={<GenericContent />} />
        <Route path="Search/:searchvalue" element={<Search />} />
        <Route path="Search" element={<Search />} />
        <Route path="image.gallery" element={<ImageGallery />} />
        <Route path="video.gallery" element={<VideoGallery />} />
        <Route path="suggestions.complaints" element={<Complaints />} />
        <Route path="sitemap" element={<SiteMap />} />
        <Route path="polls" element={<Polls />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
    </ScrollToTop>
  );
}
