import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";

import { COLORS } from "../../../shared/constants";
import HomeTopBannersIcons from "../../homeIndex/components/pureComponents/HomeTopBannersIcons";
import { useTranslation } from "react-i18next";

const newsListBanner = require("../../../assets/images/news2.png");
interface SearchNewsBannerProp {
  mainImageFullUrl?: string;
  bannerTitle: string;
  showIcons: boolean;
  showPath: boolean;
}
const SearchNewsBanner: React.FC<SearchNewsBannerProp> = (props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box position={"relative"} sx={{ backgroundColor: COLORS.secondary }}>
        <CardMedia
          component="img"
          sx={{
            borderBottom: "none",
            backgroundColor: COLORS.black,
            maxHeight: 375,
          }}
          image={
            props.mainImageFullUrl ? props.mainImageFullUrl : newsListBanner
          }
          alt="image"
        />
        {props.showPath && (
          <Box p={2}>
            <Typography variant="subtitle2">{t("urhere")}</Typography>
          </Box>
        )}
        <Box
          position={"absolute"}
          top={{
            xs: "10%",
            sm: "10%",
            md: "7%",
            lg: "8%",
            xl: "8%",
          }}
          width={"100%"}
        >
          <Box display={"flex"} justifyContent={"space-between"} paddingX={2}>
            <Box width={"50%"} mt={7}>
              <Typography
                fontSize={"1.65vw"}
                variant={"h5"}
                color={COLORS.white}
                marginX={4}
              >
                {props.bannerTitle}
              </Typography>
            </Box>
            {/* {props.showIcons && <HomeTopBannersIcons />} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchNewsBanner;
