export const MENU = "menu";
export const WEATHER = "weather";


export const NEWSLATEST = "newsLatest";
export const EVENTSLATEST = "eventslatest";
export const HOMETOPBANNERS = "hometopbanners";
export const SUPPORTEDBY = "supportedby";
export const MESSAGES = "messages";
export const IMAGEGALLERYSEARCH = "imagegallerysearch";
export const WELCOMPOPUP = "welcompopup";
export const ADVANCESEARCHPAGE = "advancesearchpage";
export const POLLSPAGE = "pollspage";
export const SENDTOFRIEND = "sendtofriend";
export const SUBSCRIBE = "subscribe";

export const WEBLINKS = "weblinks";
export const OURLOCATION = "ourlocation";
export const SOCIALMEDIA = "socialmedia";
export const PLUGINS = "plugins";
export const MOBILEICONS = "mobileicons";
export const VISITORS = "visitors";

export const IMAGEGALLERY = "imagegallery";
export const VIDEOGALLERY = "videogallery";
export const BANNERMAGAZINE = "bennermagazine";
export const BANNERBROCHURE = "bennerbrochure";

export const NEWSBYUNIQUENAME = "newsbyuniquename";
export const GENERICCONTENTBYUNUQIENAME = "genericcontentbyuniquename";
export const CATEGORIES = "categories";
export const SEARCHCATEGORIES = "searchcategories";

export const COMPANYREGISTRATION = "companyregistration";
export const COMPANYPLEDGE = "companypledge";

export const COMPLAINTS = "complaints";