import React from "react";
import { useQuery } from "@tanstack/react-query";
import { MenuDM } from "../types/index";
import { MENU } from "../queryKeyes";

export type Geolocation = {
  latitude: number;
  longitude: number;
};
const dubaiGeolocation: Geolocation = {
    latitude: 25.2048,
    longitude: 55.2708,
}
const useGeolocation = () => {
  const [geolocationState, setGeolocationState] = React.useState<Geolocation>(dubaiGeolocation);

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setGeolocationState({ latitude: latitude, longitude: longitude });
      },
      function (error) {
        console.log("Error:", error.message);
      }
    );
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
  return [geolocationState.latitude,geolocationState.longitude];
};

export default useGeolocation;
