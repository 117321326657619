import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { loginMutation } from "../shared/hooks/useLoginMutation";
import { toast } from "react-toastify";

type TransformResFn<T, R = unknown> = (res: R) => T;

type TErrorResponse = {
  error: {
    status?: number;
    name?: string;
    message?: string;
    details?: Record<string, any>;
  };
  data?: any;
};
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
type MyHeaders = AxiosRequestHeaders & {
  authorization?: string;
};
// Request interceptor to pass token from local storage in request headers
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const auth = token ? `Bearer ${token}` : "";
  config.headers = {
    ...config.headers,
    authorization: auth,
  } as MyHeaders;
  return config;
});

// response interceptor to catch all errors
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function ({ response }: AxiosError<TErrorResponse>) {
    if(response?.status === 401){
      const loginData = {
        userName: process.env.REACT_APP_USER_NAME!,
        password: process.env.REACT_APP_PASSWORD!
      };
      loginMutation(loginData);
    }
    if(response?.status){
      console.log("clientError", `${response?.status} ${response?.statusText}`);
    }
    if(response?.status === 404){
    toast(response?.statusText, {
      type:"warning",
      position: toast.POSITION.TOP_CENTER,
    });
  }
  }
);

/**
 * @param string url - endpoint url
 * @param transformResponse callback to change response data
 * @param {AxiosRequestConfig} config - get request config
 * @returns Promise
 */
const get = <T = AxiosResponse, R = Record<string, any>>(
  url: string,
  transformResponse?: TransformResFn<T, R>,
  config?: AxiosRequestConfig
): Promise<T> => {
  return instance.get(url, config).then((res: any) => {
    return new Promise<T>((resolve) => {
      if (transformResponse) {
        resolve(transformResponse(res.data));
      } else {
        resolve(res);
      }
    });
  });
};

/**
 * @param string url - endpoint url
 * @param Record<string,any> data - reqeust data
 * @param {AxiosRequestConfig} requestConfig - post request config
 * @returns Promise
 */
const post = <T = AxiosResponse, R = Record<string, any>>(
  url: string,
  data: Record<string, any>,
  transformResponse?: TransformResFn<T, R>,
  config?: AxiosRequestConfig
): Promise<T> => {
  return instance.post(url, data, config).then((res: any) => {
    return new Promise<T>((resolve) => {
      if (transformResponse) {
        resolve(transformResponse(res.data));
      } else {
        resolve(res);
      }
    });
  });
};

const API = {
  get,
  post,
};

export default API;

// to use map function from
// [
//     {
//       "name": "string",
//       "uniqueName": "string",
//       "description": "string",
//       "imageFullUrl": "string"
//     }
//   ]
// to
// interface MyData {
//     description: string;
//     imageFullUrl: string;
//   }

// you have to do it like this

//   const transformResponse = (data: any): MyData[] => {
//     return data.map((item: any) => ({
//       description: item.description,
//       imageFullUrl: item.imageFullUrl,
//     }));
//   };

//   get<MyData[]>(url, transformResponse).then((response) => {
//     console.log(response);
//   });
