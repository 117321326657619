import React from "react";
import { NavLink, To } from "react-router-dom";
interface NGuardNavLinkProp {
  to: To;
  title: string;
  borderBottom?: string;
  paddingBottom?: number;
  bannerImage?: string | null;
}
const NGuardNavLink: React.FC<NGuardNavLinkProp> = (props) => {
  return (
    <NavLink
      state={{ bannerImage: props.bannerImage }}
      style={({ isActive }) =>
        isActive
          ? {
              color: "black",
              display: "block",
              textDecoration: "none",
              fontWeight: "bold",
              borderBottom: props.borderBottom,
              // paddingBottom: 8,
              paddingBottom: props.paddingBottom,
              width: "100%",
            }
          : {
              color: "black",
              display: "block",
              textDecoration: "none",
              width: "100%",
              borderBottom: props.borderBottom,
              // paddingBottom: 8,
              paddingBottom: props.paddingBottom,
            }
      }
      to={props.to}
    >
      {props.title}
    </NavLink>
  );
};

export default NGuardNavLink;
