import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { SupportedByDM } from "../types";
import { SUPPORTEDBY } from "../../../shared/queryKeyes";

  const supportedByFetching = async (languageId: number): Promise<SupportedByDM[]> => {
    const response = await API.get(`Weblinks/WeblinksByCategory?categoryUniqueName=supported.by&count=5&langId=${languageId}`);
    return response.data;
  };
  const useSupportedBy = (languageId: number) => {
    return useQuery<SupportedByDM[],Error>([SUPPORTEDBY, languageId], () => supportedByFetching(languageId));
  };

  export default useSupportedBy;