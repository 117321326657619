import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { NEWSBYUNIQUENAME } from "../../../shared/queryKeyes";
import { NewsDetailsDM } from "../../homeIndex/types";

const newsByUniqueNameFetching = async (
  languageId: number,
  uniqueName: string
): Promise<NewsDetailsDM> => {
  const response = await API.get(
    `News/ContentByUniqueName?uniqueName=${uniqueName}&langId=${languageId}`
  );
  return response.data;
};
const useNewsByUniqueName = (languageId: number, uniqueName: string) => {
  return useQuery<NewsDetailsDM, Error>(
    [NEWSBYUNIQUENAME, languageId, uniqueName],
    () => newsByUniqueNameFetching(languageId, uniqueName)
  );
};

export default useNewsByUniqueName;
