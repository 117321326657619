import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import { Settings, Close, Contrast } from "@mui/icons-material";
import {
  Drawer,
  Divider,
  List,
  useTheme,
  styled,
  Typography,
  Box,
  Stack,
  Button,
  Container,
  Paper,
} from "@mui/material";
import { COLORS } from "../../../shared/constants";
import {
  blue,
  bluePalette,
  goldPalette,
  golden,
  green,
  greenPalette,
  red,
  redPalette,
} from "../../../theme/theme";
import useThemeChanger from "../hooks/useThemeChanger";
import { useTranslation } from "react-i18next";

interface ThemeDrawerProp {
  handleDrawerClose: () => void;
  drawerState: boolean;
}
const drawerWidth = 440;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const ThemeDrawer: React.FC<ThemeDrawerProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleThemeChange, resetTheme, contrastSetter } = useThemeChanger();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          //   marginTop: '67px',
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor={theme.direction === "ltr" ? "left" : "right"}
      open={props.drawerState}
      onClose={props.handleDrawerClose}
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: "100%",
          borderRadius: 0,
        }}
      >
        <DrawerHeader>
          <Box display={"flex"} alignItems={"center"}>
            <Settings sx={{ color: COLORS.white,marginInlineEnd:1 }} />
            <Typography color={COLORS.white}>{t("settings")}</Typography>
          </Box>

          <IconButton onClick={props.handleDrawerClose}>
            <Close sx={{ color: COLORS.white }}/>
          </IconButton>
        </DrawerHeader>
        <Divider />

        <Paper sx={{ backgroundColor: COLORS.white, margin: "8%" }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8%",
              gap: 2,
            }}
          >
            <Typography>{t("font_size")}</Typography>
            <List
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 2,
              }}
            >
              <Button
                color="info"
                variant="outlined"
                onClick={() => handleThemeChange({ fontSize: 15 ,direction: theme.direction})}
              >
                A+
              </Button>
              <Button
                color="info"
                variant="outlined"
                onClick={() => handleThemeChange({ fontSize: 14,direction: theme.direction })}
              >
                A
              </Button>
              <Button
                color="info"
                variant="outlined"
                onClick={() => handleThemeChange({ fontSize: 13,direction: theme.direction })}
              >
                A-
              </Button>
            </List>
          </Container>
        </Paper>

        <Paper sx={{ backgroundColor: COLORS.white, margin: "8%" }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8%",
              gap: 2,
            }}
          >
            <Typography>{t("color_themes")}</Typography>
            <List
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: 2,
              }}
            >
              <Button
                onClick={() => handleThemeChange({ palette: goldPalette,direction: theme.direction })}
              >
                <Box sx={{ backgroundColor: golden }} width={50} height={50} />
              </Button>
              <Button
                onClick={() => handleThemeChange({ palette: redPalette ,direction: theme.direction})}
              >
                <Box sx={{ backgroundColor: red }} width={50} height={50} />
              </Button>
              <Button
                onClick={() => handleThemeChange({ palette: bluePalette ,direction: theme.direction})}
              >
                <Box sx={{ backgroundColor: blue }} width={50} height={50} />
              </Button>
              <Button
                onClick={() => handleThemeChange({ palette: greenPalette ,direction: theme.direction})}
              >
                <Box sx={{ backgroundColor: green }} width={50} height={50} />
              </Button>
            </List>
          </Container>
        </Paper>
        <Paper sx={{ backgroundColor: COLORS.white, margin: "8%" }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "5%",
              gap: 2,
            }}
          >
            <IconButton
              onClick={contrastSetter}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                borderRadius: "0",
                width: "40%",
              }}
            >
              <Typography>{t("contrast")}</Typography>
              <Contrast />
            </IconButton>
          </Container>
        </Paper>
        <Button
          sx={{
            backgroundColor: COLORS.white,
            color: theme.palette.primary.main,
            margin: "8%",
          }}
          variant="contained"
          onClick={resetTheme}
        >
          {t("reset")}
        </Button>
      </Paper>
    </Drawer>
  );
};

export default ThemeDrawer;
