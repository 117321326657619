import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../shared/constants";
import useMessages from "../hooks/useMessages";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import { useNavigate } from "react-router-dom";
import { Scale } from "@mui/icons-material";

const newsAndUpdates1 = require("../../../assets/images/desktop.png");

interface MessagesSectionProp {}
const MessagesSection: React.FC<MessagesSectionProp> = (props) => {
  const nagivate = useNavigate();
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useMessages(languageId);

  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return null;
  }
  return (
    <Box id='our-leaders' className="ignore-print">
      <Typography
        variant="h4"
        sx={{
          display: "block",
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        {t("ourleaders")}
      </Typography>
      <Grid container sx={{ marginTop: 5 }} gap={10} justifyContent={"center"}>
        {data?.map((item) => {
          return (
            <Card
              key={item.uniqueName}
              onClick={() => nagivate(`GenericContent/${item.uniqueName}`)}
              className="imgHover"
              sx={{
                maxWidth: 220,
                maxHeight: 360,
                marginX: "2px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: '0.5s',
                '&:hover': {
                  transform: 'Scale(1.1)',
                  zIndex:2
                },
              }}
            >
              <CardActionArea
                sx={{ height: "100%", backgroundColor: "inherit" }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    // height: '80%',
                    height: 290,
                    width: 220,
                  }}
                  // image={newsAndUpdates1}
                  image={
                    item.relatedImages &&
                    item.relatedImages.length > 0 &&
                    item.relatedImages[0].fullUrl !== ""
                      ? item.relatedImages[0].fullUrl
                      : newsAndUpdates1
                  }
                  alt="image"
                />
                <CardContent sx={{ height: "20%" }}>
                  <Typography variant="body2" color="text.secondary">
                    {item.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MessagesSection;
