import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../../client/api";
import { COLORS } from "../../shared/constants";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import { useNavigate } from "react-router-dom";
import NewsLatestSection from "../homeIndex/components/NewsLatestSection";
import SearchNewsForm from "./components/SearchNewsForm";
import { SearchNewsVM } from "./types";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import NoMatch from "../noMatch/NoMatch";
import { useTranslation } from "react-i18next";
import SearchNewsBanner from "./components/SearchNewsBanner";
import { DateFormatedByLanguage } from "../../shared/utils";

interface NewsItem {
  title: string;
  uniqueName: string;
  mainImageFullUrl: string;
  headline: string;
  summary: string;
  newsDate: string;
  contentData: string;
  lastModificationDate: string;
  relatedEntities: any[];
  relatedLinks: any[];
  categories: {
    name: string;
    uniqueName: string;
    description: string;
    imageFullUrl: string;
  }[];
  ratingAvg: null;
}
interface NewsPage {
  totalCount: number;
  result: NewsItem[];
}

const PAGE_SIZE = 5;

const fetchNews = async (
  pageParam = 1,
  langId: number,
  keyword: string,
  categoryUniqueName: string,
  dateFrom: string,
  dateTo: string
): Promise<NewsPage> => {
  const response = await API.get(
    `News/Search?pageSize=${PAGE_SIZE}&pageNumber=${pageParam}
    &langId=${langId}&keyword=${keyword}&categoryUniqueName=${categoryUniqueName}
    &fromDate=${dateFrom}&toDate=${dateTo}`
    // &fromDate=2011-07-19T00:00:00&toDate=2019-07-19T00:00:00&`
  );
  return response.data;
};

interface NewsListProp {}

//TODO: Refactoring
const NewsList: React.FC<NewsListProp> = (props) => {
  const { t } = useTranslation();
  const nagivate = useNavigate();
  const theme = useTheme();
  const languageId = useLanguageID();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchNewsState, setSearchNewsState] = useState<SearchNewsVM>({
    key: "",
    category: "",
    dateFrom: "",
    dateTo: "",
  });
  const { data, isLoading, isError, refetch }: UseQueryResult<NewsPage, Error> =
    useQuery(["news", pageNumber, languageId], () =>
      fetchNews(
        pageNumber,
        languageId,
        searchNewsState.key,
        searchNewsState.category,
        searchNewsState.dateFrom,
        searchNewsState.dateTo
      )
    );
    const location = useLocation();
    const bannerImage =
      location.state &&
      (location.state as any).bannerImage &&
      (location.state as any).bannerImage.toString();
  const refetchPassed = () => {
    refetch();
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <NoMatch />;
  }

  const { result, totalCount } = data;
  const pageCount = Math.ceil(totalCount / PAGE_SIZE);

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  const getPageButtonRange = () => {
    let firstPage = Math.max(1, pageNumber - 2);
    let lastPage = Math.min(pageCount, pageNumber + 2);

    if (firstPage === 1) {
      lastPage = Math.min(pageCount, 5);
    }

    if (lastPage === pageCount) {
      firstPage = Math.max(1, pageCount - 4);
    }

    return Array.from(
      { length: lastPage - firstPage + 1 },
      (_, i) => firstPage + i
    );
  };
  const lang = theme.direction === "ltr" ? "en-US" : "ar";
  const maxLength = 350;
  return (
    <>
      <SearchNewsBanner
        bannerTitle={t("newsarticles")}
        showIcons={true}
        showPath={true}
        mainImageFullUrl={bannerImage}
      />
      <Box marginX={"8%"} marginTop={6}>
        <Stack marginY={6} spacing={6}>
          <NewsLatestSection withSectionHeader={true} insie={true} />
          <SearchNewsForm
            refetchPassed={refetchPassed}
            searchNewsState={searchNewsState}
            setSearchNewsState={setSearchNewsState}
          />
        </Stack>
        <Typography letterSpacing={1} color={"primary"}>
          {`${t("showing")} ${
            PAGE_SIZE < totalCount ? PAGE_SIZE : totalCount
          } ${t("of")} ${totalCount} ${t("results")}`}
        </Typography>
        <Box mt={1}>
          {result.map((newsItem) => {
            const formattedDate = DateFormatedByLanguage(newsItem.newsDate,languageId)

            const html = newsItem.contentData;
            const text = html.replace(/<[^>]+>/g, ""); // Removes all HTML tags from the text
            const truncatedText =
              text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
            return (
              <Card
                key={newsItem.uniqueName}
                sx={{
                  display: "flex",
                  flexDirection:{xs: 'column',md:'row'},
                  gap: 3,
                  backgroundColor: "inherit",
                  borderBottom: `2px solid ${COLORS.secondary}`,
                  paddingBottom: 2,
                  marginY: 2,
                }}
                elevation={0}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    maxWidth: 280,
                    // padding: 1,
                    border: `1px solid ${COLORS.secondary}`,
                  }}
                >
                  <IconButton
                    onClick={() => nagivate(`${newsItem.uniqueName}`)}
                    sx={{ width: "100%", height: 200, padding: 0 }}
                  >
                    <img
                      width={"100%"}
                      height={200}
                      src={newsItem.mainImageFullUrl}
                    />
                  </IconButton>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="body1"
                      fontWeight={"bold"}
                    >
                      {formattedDate}
                    </Typography>
                    <Typography variant="body1" component="div" mt={1}>
                      {newsItem.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      color="text.secondary"
                      dangerouslySetInnerHTML={{ __html: truncatedText }}
                      mt={1}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      sx={{ textDecoration: "underline" }}
                      onClick={() => nagivate(`${newsItem.uniqueName}`)}
                    >
                      {t("readmore")}
                    </Button>
                  </CardActions>
                </Box>
              </Card>
            );
          })}
        </Box>

        <Box
          display="flex"
          flexDirection={{xs:'column',sm:'row'}}
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <ButtonGroup variant="text" aria-label="outlined button group">
            <Button
              onClick={() => handlePageChange(1)}
              disabled={pageNumber === 1}
            >
              {t("first")}
            </Button>
            <Button
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              {t("previous")}
            </Button>
          </ButtonGroup>

          <Box marginX={0}>
            {getPageButtonRange().map((page) => (
              <Button
                // sx={{ marginX: 1 }}
                key={page}
                onClick={() => handlePageChange(page)}
                disabled={pageNumber === page}
              >
                {page}
              </Button>
            ))}
          </Box>

          <ButtonGroup variant="text" aria-label="outlined button group">
            <Button
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={pageNumber === pageCount}
            >
              {t("next")}
            </Button>
            <Button
              onClick={() => handlePageChange(pageCount)}
              disabled={pageNumber === pageCount}
            >
              {t("last")}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export default NewsList;
