import React from "react";
import { Box, Stack } from "@mui/material";

import useFooter from "../hooks/useFooter";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import { COLORS } from "../../../shared/constants";
import FooterHighlights from "./pureComponents/FooterHighlights";
import FooterPrimaryInforamtion from "./pureComponents/FooterPrimaryInforamtion";
import FooterSecondaryInformation from "./pureComponents/FooterSecondaryInformation";
import useVisitorsMutation, { postVisitorMutation } from "../../../shared/hooks/useVisitorsMutation";

interface FooterSectionProp {}

const FooterSection: React.FC<FooterSectionProp> = (props) => {
  const languageId = useLanguageID();
  const {
    webLinksRQR,
    ourLocationRQR,
    socialMediaRQR,
    pluginsRQR,
    mobileIconsRQR,
    visitorsRQR,
  } = useFooter(languageId);

  const {postVisitor} = useVisitorsMutation(languageId);

  React.useEffect(() => {
    const localStorageKeyBasedLanguageId = languageId === 1 ? "enVisited" : "arVisited";
    const visited = sessionStorage.getItem(localStorageKeyBasedLanguageId)
    if(!visited){
      postVisitor();
      visitorsRQR.refetch();    
    }
  },[languageId])
  if (
    webLinksRQR.isLoading ||
    ourLocationRQR.isLoading ||
    socialMediaRQR.isLoading ||
    pluginsRQR.isLoading ||
    visitorsRQR.isLoading
  ) {
    return <CustomSkeleton />;
  }
  if (
    webLinksRQR.isError ||
    ourLocationRQR.isError ||
    socialMediaRQR.isError ||
    pluginsRQR.isError ||
    visitorsRQR.isError
  ) {
    return null;
  }

  const copyRight = ourLocationRQR.data;
  return (
    <Box>
      <Stack sx={{ backgroundColor: COLORS.secondary }}>
        <FooterPrimaryInforamtion
          ourLocationData={ourLocationRQR.data?.contentData}
          webLinksRQRData={webLinksRQR.data}
        />
        <FooterSecondaryInformation
          pluginsRQRData={pluginsRQR.data}
          socialMediaRQRData={socialMediaRQR.data}
          relatedLinks={ourLocationRQR.data.relatedLinks}
          mobileIconsRQRData={mobileIconsRQR.data}
        />
      </Stack>

      <FooterHighlights
        relatedHIghlights={ourLocationRQR.data?.relatedHIghlights}
        visitors={visitorsRQR.data}
      />
    </Box>
  );
};

export default FooterSection;

{
  /* <Divider
orientation="vertical"
flexItem
sx={{
  margin: "1%",
  backgroundColor: "#ddd",
  display: { xs: "none", md: "flex" },
}}
/> */
}
// sx={{ borderLeft: "2px solid #ddd", paddingX: "2%" }}
