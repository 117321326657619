import React from "react";
import { Box, Stack, styled, useTheme } from "@mui/material";
import {
  RingVolume,
  SentimentSatisfiedAlt,
  BusinessCenter,
  Print,
} from "@mui/icons-material";

import { COLORS } from "../../../../shared/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ComplaintsIcon from "../../../../shared/components/svg/Complaints";
import useGenericContent from "../../../../shared/hooks/useGenericContent";
import { useLanguageID } from "../../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../../shared/components/skelton/CustomSkeleton";

interface HomeTopBannersIconsProp {}
const IconBox = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.white,
  padding: 6,
  borderRadius: "50%",
  '&:hover': {
    cursor: 'pointer',
  },
}));
const HomeTopBannersIcons: React.FC<HomeTopBannersIconsProp> = (props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const languageId = useLanguageID();
  const navigate = useNavigate();
  const {data,isLoading,isError} = useGenericContent(
    languageId,
    "suggestions.complaints"
  );
  if(isLoading){
    return <CustomSkeleton />
  }
  if(isError){
    return null;
  }
 const handlePrint = () => {
    window.print();
  };
  return (
    <Stack
      spacing={2}
      marginX={0}
      padding={1.5}
      paddingY={3}
      height={"40%"}
      mt={6}
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "block",
        },
      }}
    >
      {/* <IconBox>
        <Share />
      </IconBox> */}
      <IconBox onClick={() => navigate('/GenericContent/contact.us')} title={t('contact_us')}>
        <RingVolume />
      </IconBox>

      <IconBox onClick={() => navigate('/polls')} title={t('polls')}>
        <SentimentSatisfiedAlt  sx={{marginLeft:'.80px',marginTop:'.60px'}}/>
      </IconBox>
      <IconBox onClick={() => navigate('/suggestions.complaints')} title={data.title}>
        <ComplaintsIcon />
      </IconBox>
      <IconBox title={t('careers')}>
        <BusinessCenter onClick={() => navigate('/GenericContent/careers')}/>
      </IconBox>
      {/* <IconBox>
        <SpeakerNotes />
      </IconBox> */}
      <IconBox onClick={handlePrint} title={t('print')}>
        <Print />
      </IconBox>
    </Stack>
  );
};

export default HomeTopBannersIcons;
