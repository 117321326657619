import { Box, Skeleton } from "@mui/material";
import React from "react";

interface HomeTopBannerSkeletonProp {}

const HomeTopBannerSkeleton: React.FC<HomeTopBannerSkeletonProp> = (props) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={5} m={2}>
      <Skeleton variant="rectangular" width={"95%"} height={45} />
      <Skeleton variant="rectangular" width={"95%"} height={45} />
      <Skeleton variant="rounded" width={"95%"} height={45} />
      <Skeleton variant="rounded" width={"95%"} height={45} />
      <Skeleton variant="rounded" width={"95%"} height={45} />
    </Box>
  );
};

export default HomeTopBannerSkeleton;
