import { Box, Typography } from "@mui/material";

export default function NoMatch() {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={10}
    >
      <Typography fontFamily={'Roboto'} fontWeight={"600"} color={"primary"} variant="h1">
        404 NOT FOUND
      </Typography>
    </Box>
  );
}
