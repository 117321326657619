import React from "react";
import useHellopPopCoockies from "../../../shared/hooks/useHellopPopCoockies";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import useWelcomPopup from "../hooks/useWelcomPopup";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import { Close } from "@mui/icons-material";
interface WelcomPopupProp {}
const WelcomPopup: React.FC<WelcomPopupProp> = (props) => {
  const [openHelloPopup, setOpenHelloPopup] = React.useState(true);
  const { getHellopPopCookieValue, setHellopPopCookie } =
    useHellopPopCoockies();

  // const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useWelcomPopup(languageId);

  const handleHelloPopupOpen = () => {
    setOpenHelloPopup(true);
  };
  const handleHelloPopupClose = () => {
    setHellopPopCookie();
    setOpenHelloPopup(false);
  };

  const helloPopUpCookie = getHellopPopCookieValue();

  React.useEffect(() => {
    if (helloPopUpCookie === "true") {
      handleHelloPopupClose();
    } else {
      handleHelloPopupOpen();
    }
  }, [helloPopUpCookie]);

  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError || data.length === 0) {
    return null;
  }
  const popUpData = data[0];
  return (
    <Dialog open={openHelloPopup} maxWidth={"xs"} fullWidth sx={{zIndex:2303}}>
      <DialogTitle>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="subtitle2">{popUpData.title}</Typography>
          <IconButton onClick={handleHelloPopupClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <IconButton onClick={() => window.open(popUpData.linkUrl, "_blanck")}>
          <img width={"100%"} height={"auto"} src={popUpData.fullUrl} />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomPopup;
