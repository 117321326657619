import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { COLORS } from "../../../shared/constants";
import { SearchEventsVM } from "../types";
// import useCategory from "../hooks/useCategory";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import { CategoryVM } from "../../homeIndex/types";
import useCategory from "../../newsList/hooks/useCategory";
import { altCategories } from "../../newsList/utils";
// import { altCategories } from "../utils";
interface SearchEventsFormProp {
  searchNewsState: SearchEventsVM;
  setSearchNewsState: React.Dispatch<React.SetStateAction<SearchEventsVM>>;
  refetchPassed: () => void;
}

const SearchEventsForm: React.FC<SearchEventsFormProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useCategory(languageId);

  const categories: CategoryVM[] | undefined = data?.map((item) => {
    const category_vm: CategoryVM = {
      label: item.name,
      uniqueName: item.uniqueName,
    };
    return category_vm;
  });

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setSearchNewsState({
      ...props.searchNewsState,
      key: event.target.value,
    });
  };
  const handleCategoryChange = (
    data: { label: string; uniqueName: string } | null
  ) => {
    props.setSearchNewsState({
      ...props.searchNewsState,
      category: data?.uniqueName ?? "",
    });
  };
  const handleDateFromChange = (data: unknown) => {
    try {
      const date = dayjs(data as Dayjs).toDate();
      if (!isNaN(date.getTime())) {
        const dateFrom = date.toISOString();
        props.setSearchNewsState({
          ...props.searchNewsState,
          dateFrom: dateFrom,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDateToChange = (data: unknown) => {
    try {
      const date = dayjs(data as Dayjs).toDate();
      if (!isNaN(date.getTime())) {
        const dateFrom = date.toISOString();
        props.setSearchNewsState({
          ...props.searchNewsState,
          dateFrom: dateFrom,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          display: "block",
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        {t("searchevents")}
      </Typography>
      <Grid
        paddingX={"4%"}
        paddingTop={"3%"}
        paddingBottom={"4%"}
        container
        gap={2}
        sx={{
          backgroundImage: `linear-gradient(to bottom,${theme.palette.primary.main} , ${COLORS.white})`,
        }}
      >
        <Grid item container alignItems={"center"} gap={3}>
          <Grid item xs={3} md={1} display={"flex"} justifyContent={"center"}>
            <Typography variant="subtitle2">{t("key")}</Typography>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              onChange={handleKeyChange}
            />
          </Grid>
          <Grid item xs={3} md={1} display={"flex"} justifyContent={"center"}>
            <Typography variant="subtitle2">{t("category")}</Typography>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Autocomplete
              onChange={(event, cat) => handleCategoryChange(cat)}
              disablePortal
              size="small"
              id="combo-box-demo"
              options={categories ?? altCategories}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>

        <Grid item container alignItems={"center"} gap={3}>
          <Grid item xs={3} md={1} display={"flex"} justifyContent={"center"}>
            <Typography variant="subtitle2">{t("fromdate")}</Typography>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <DatePicker
              sx={{ width: "100%" }}
              slotProps={{ textField: { size: "small" } }}
              onChange={handleDateFromChange}
            />
          </Grid>
          <Grid item xs={3} md={1} display={"flex"} justifyContent={"center"}>
            <Typography variant="subtitle2">{t("todate")}</Typography>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <DatePicker
              sx={{ width: "100%" }}
              onChange={handleDateToChange}
              slotProps={{ textField: { size: "small" } }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={props.refetchPassed}
              variant="contained"
              sx={{ color: COLORS.white }}
            >
              {t('search')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchEventsForm;
