import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { MouseEvent } from "react";
import { NavLink } from "react-router-dom";
import { linkStyle } from "../utils";
import { COLORS, customPages } from "../../../shared/constants";
import { MenuDM } from "../../../shared/types";
import { useTranslation } from "react-i18next";
interface SiteMapContainerProp {
  mainsitenavigation: MenuDM[];
  sitequicklinks: MenuDM[];
}
const SiteMapContainer: React.FC<SiteMapContainerProp> = (props) => {
  const { t } = useTranslation();
  const handleMouseEnter = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = COLORS.primary;
  };
  const handleMouseLeave = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = COLORS.black;
  };

  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={5}>
        <Accordion
          expanded
          variant="outlined"
          sx={{ backgroundColor: "inherit", border: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"500"} variant="h5">
              {t("mainsitenavigation")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props.mainsitenavigation.map((item,index) => {
              return (
                <Accordion
                key={index}
                  variant="outlined"
                  sx={{ backgroundColor: "inherit", border: "none" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h6">{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.children.map((childItem,index) => {
                      const existingIncustom = customPages.find(
                        (item) =>
                          item.toLocaleLowerCase() ===
                          childItem.contentUniquename?.toLowerCase()
                      );
                      return (  
                        <NavLink
                        key={index}
                          style={linkStyle}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          to={
                            existingIncustom
                              ? `/${childItem.contentUniquename!}`
                              : `/GenericContent/${childItem.contentUniquename}`
                          }
                        >
                          {childItem.title}
                        </NavLink>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={5}>
        <Accordion
          expanded
          variant="outlined"
          sx={{ backgroundColor: "inherit", border: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"500"} variant="h5">
              {t("sitequicklinks")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props.sitequicklinks.map((item,index) => {
              const existingIncustom = customPages.find(
                (customPagesItem) =>
                  customPagesItem.toLocaleLowerCase() ===
                  item.contentUniquename?.toLowerCase()
              );
              return (
                <NavLink
                key={index}
                  style={linkStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to={
                    existingIncustom
                      ? `/${item.contentUniquename!}`
                      : `/GenericContent/${item.contentUniquename}`
                  }
                >
                  {item.title}
                </NavLink>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default SiteMapContainer;
