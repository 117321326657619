import { useQuery } from "@tanstack/react-query";
import API from "../../../client/api";
import { WebLinksDM,OurLocationDM, SocialMediaDM, PluginDM, BannersByCategoryDM } from "../types";
import { MOBILEICONS, OURLOCATION, PLUGINS, SOCIALMEDIA, VISITORS, WEBLINKS } from "../../../shared/queryKeyes";

const webLinksFetching = async (languageId: number): Promise<WebLinksDM[]> => {
  const response = await API.get(
    `Weblinks/WeblinksByCategory?categoryUniqueName=important.links&count=14&langId=${languageId}`
  );
  return response.data;
};
const ourLocationFetching = async (languageId: number): Promise<OurLocationDM> => {
  const response = await API.get(
    `GenericContent/ContentByUniqueName?uniqueName=our.location&langId=${languageId}`
  );
  return response.data;
};
const socialMediaFetching = async (languageId: number): Promise<SocialMediaDM[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=bottom.social.media&langId=${languageId}`
  );
  return response.data;
};

const pluginsFetching = async (languageId: number): Promise<PluginDM[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=footer.plugins.zone&langId=${languageId}`
  );
  return response.data;
};
const mobileIConFetching = async (languageId: number): Promise<BannersByCategoryDM[]> => {
  const response = await API.get(
    `Banner/BannersByCategory?categoryUniqueName=mobile.app.icons&langId=${languageId}`
  );
  return response.data;
};
const visitorsFetching = async (languageId: number): Promise<number> => {
  const response = await API.get(
    `GetVisitorNumber?lang=${languageId}`
  );
  return response.data;
};
const useFooter = (languageId: number) => {

  const webLinksRQR =  useQuery<WebLinksDM[], Error>([WEBLINKS, languageId], () =>
  webLinksFetching(languageId)
  );

  const ourLocationRQR =  useQuery<OurLocationDM, Error>([OURLOCATION, languageId], () =>
  ourLocationFetching(languageId)
  );

  const socialMediaRQR =  useQuery<SocialMediaDM[], Error>([SOCIALMEDIA, languageId], () =>
  socialMediaFetching(languageId)
  );

  const pluginsRQR =  useQuery<PluginDM[], Error>([PLUGINS, languageId], () =>
  pluginsFetching(languageId)
  );
  const mobileIconsRQR =  useQuery<PluginDM[], Error>([MOBILEICONS, languageId], () =>
  mobileIConFetching(languageId)
  );
  const visitorsRQR =  useQuery<number, Error>([VISITORS, languageId], () =>
  visitorsFetching(languageId)
  );
  return {webLinksRQR,ourLocationRQR,socialMediaRQR,pluginsRQR,mobileIconsRQR,visitorsRQR}
};

export default useFooter;
