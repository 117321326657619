import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SUBSCRIBE } from "../../../shared/queryKeyes";
import API from "../../../client/api";
export type SubscribeData = {
    emailAddress: string;
};

type SubscribeResponse = {
  status: string;
  messageEn: string;
  messageAr: string;
};

export const sendToFriendPost = async (
  data: SubscribeData
): Promise<SubscribeResponse> => {
  const response = await API.post(`newsletter/subscribe`, {
    emailAddress: data.emailAddress,
  });
  return response.data;
};
const useSubscribe = () => {
  const queryClient = useQueryClient();
  const { data, isError, error, isLoading, mutate } = useMutation<
    SubscribeResponse,
    Error,
    SubscribeData
  >(sendToFriendPost);

  const submit = async (dataPassed: SubscribeData) => {
    await mutate(dataPassed);
    await queryClient.refetchQueries([SUBSCRIBE]);
  };
  return {
    submit,
    isSubscribeMutationError: isError,
    subscribeMutationError: error,
    subscribeMutationIsLoading: isLoading,
    subscribeData: data,
  };
};

export default useSubscribe;
