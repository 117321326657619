import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import { VIDEO_GALLERYPAGE_SIZE } from "./hooks/useImgageGallerySearch";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import NoMatch from "../noMatch/NoMatch";
import { COLORS } from "../../shared/constants";
import useVideoGallerySearch from "./hooks/useImgageGallerySearch";
import { SubscriptionsOutlined } from "@mui/icons-material";
import FormTitle from "../../shared/components/typographies/FormTitle";
import SubPageTitle from "../../shared/components/typographies/SubPageTitle";
import VideoGallerySearchForm from "./components/VideoGallerySearchForm";
import HomeTopBannersIcons from "../homeIndex/components/pureComponents/HomeTopBannersIcons";

interface VideoGalleryProp {}
const VideoGallery: React.FC<VideoGalleryProp> = (props) => {
  const { t } = useTranslation();
  const nagivate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const languageId = useLanguageID();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [key, setKey] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const { data, isLoading, isError, refetch } = useVideoGallerySearch(
    pageNumber,
    languageId,
    key
  );
  const [videoLink, setVideoLink] = React.useState("");
  const refetchHandler = () => {
    refetch();
  };
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKey(event.target.value);
  };
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const imageStyle = {
    cursor: hovered ? "pointer" : "default",
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <NoMatch />;
  }
  const { result, totalCount } = data;
  const pageCount = Math.ceil(totalCount / VIDEO_GALLERYPAGE_SIZE);
  const handleOpen = (videoLink: string) => {
    setVideoLink(videoLink);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  const getPageButtonRange = () => {
    let firstPage = Math.max(1, pageNumber - 2);
    let lastPage = Math.min(pageCount, pageNumber + 2);

    if (firstPage === 1) {
      lastPage = Math.min(pageCount, 5);
    }

    if (lastPage === pageCount) {
      firstPage = Math.max(1, pageCount - 4);
    }

    return Array.from(
      { length: lastPage - firstPage + 1 },
      (_, i) => firstPage + i
    );
  };
  const lang = theme.direction === "ltr" ? "en-US" : "ar";
  const maxLength = 350;
  return (
    <>
      <Box marginX={"8%"} marginTop={5}>
        <Box position={"relative"}>
          <Stack spacing={5} mb={1}>
            <SubPageTitle title={t("videogallery")} />
            <FormTitle title={t("filterBy")} />
          </Stack>
        </Box>

        <VideoGallerySearchForm
          onChangeHandler={onChangeHandler}
          refetchHandler={refetchHandler}
        />

        <Box mt={5}>
          <Grid container>
            {result.map((imgItem, index) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    transition: "0.5s",
                    "&:hover": {
                      transform: "Scale(1.1)",
                      zIndex: 2,
                    },
                  }}
                  xs={12}
                  md={4}
                  item
                >
                  <Box
                    position={"relative"}
                    p={1}
                    height={{ xs: "auto", md: 300 }}
                  >
                    <img
                      width={"100%"}
                      height={"100%"}
                      src={imgItem.mainImageFullUrl}
                      onClick={() =>
                        handleOpen(imgItem.relatedEntities?.[0]?.fullUrl)
                      }
                      style={imageStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                    <Box
                      position={"absolute"}
                      top={"40%"}
                      left={{ xs: "41%", md: "45%" }}
                    >
                      <SubscriptionsOutlined
                        sx={{
                          color: COLORS.opacityBlack,
                          width: "7vh",
                          height: "7vh",
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    borderBottom={`2px solid ${COLORS.primary}`}
                    marginX={1}
                    sx={{ backgroundColor: COLORS.secondary }}
                    fontWeight={"500"}
                    variant="subtitle1"
                    align={"center"}
                  >
                    {imgItem.title}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Typography letterSpacing={1} color={"primary"} align="center" mt={4}>
          {`${t("showing")} ${
            VIDEO_GALLERYPAGE_SIZE < totalCount
              ? VIDEO_GALLERYPAGE_SIZE
              : totalCount
          } ${t("of")} ${totalCount} ${t("results")}`}
        </Typography>
        {result && result.length > 0 ? (
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="center"
            alignItems="center"
            marginTop={2}
          >
            <ButtonGroup aria-label="outlined button group">
              <Button
                onClick={() => handlePageChange(1)}
                disabled={pageNumber === 1}
                variant="text"
              >
                {t("first")}
              </Button>
              <Button
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
                variant="text"
              >
                {t("previous")}
              </Button>
            </ButtonGroup>

            <Box marginX={0}>
              {getPageButtonRange().map((page) => (
                <Button
                  // sx={{ marginX: 1 }}
                  key={page}
                  onClick={() => handlePageChange(page)}
                  disabled={pageNumber === page}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <ButtonGroup aria-label="outlined button group">
              <Button
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber === pageCount}
                variant="text"
              >
                {t("next")}
              </Button>
              <Button
                onClick={() => handlePageChange(pageCount)}
                disabled={pageNumber === pageCount}
                variant="text"
              >
                {t("last")}
              </Button>
            </ButtonGroup>
          </Box>
        ) : (
          <Typography textAlign={"center"} variant="h5" color={"primary"}>
            {t("zeroresult")}
          </Typography>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        // maxWidth={"lg"}
        // fullWidth
        sx={{ height: "auto" }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <iframe
            width={isSmallScreen ? "350" : "500"}
            height={isSmallScreen ? "215" : "400"}
            src={videoLink}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoGallery;
