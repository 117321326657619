import React from "react";
import { goldenTheme } from "../theme/theme";
import { Theme } from "@mui/material";

export const ThemeContext = React.createContext({
  changeTheme: (themeName: Theme): void => {},
  themeData: goldenTheme,
  contrastSetGet: (value: boolean): void => {},
  isContrast: false,
  isStartTour: false,
  startTourSetGet: (value: boolean): void => {},
});
