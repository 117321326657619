import React, { MouseEvent } from "react";
import { Grid, Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { WebLinksDM } from "../../types";
import FooterPrimaryNewsLetter from "./FooterPrimaryNewsLetter";
import { COLORS, customPages } from "../../../../shared/constants";

interface FooterPrimaryInforamtionProp {
  webLinksRQRData: WebLinksDM[] | undefined;
  ourLocationData: string;
}

const FooterPrimaryInforamtion: React.FC<FooterPrimaryInforamtionProp> = (
  props
) => {
  const { t } = useTranslation();
  const linkStyle = {
    color: COLORS.black,
    textDecoration: "none",
    transition: "color 0.3s", // Add transition for smooth color change
  };
  const handleMouseEnter = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = COLORS.primary;
  };

  const handleMouseLeave = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = COLORS.black;
  };
  return (
    <Grid
      container
      paddingX={"6%"}
      paddingY={5}
      gap={2}
      justifyContent={"space-between"}
    >
      <Grid item xs={12} lg={3} paddingY={3}>
        <FooterPrimaryNewsLetter />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack gap={2}>
          <Typography variant={"h5"}>{t("importantlinks")}</Typography>
          <Grid container justifyContent={"space-between"}>
            {props.webLinksRQRData?.map((webLinkItem, i) => {
              const to = webLinkItem.uniqueName;
              const existingIncustom = customPages.find(
                (item) => item.toLocaleLowerCase() === to.toLowerCase()
              );
              return (
                <Grid item xs={6} paddingY={1} key={i}>
                  {webLinkItem.linkUrl && webLinkItem.linkUrl.length > 1 ? (
                    <MuiLink
                      onClick={() =>
                        window.open(webLinkItem.linkUrl, webLinkItem.linkTarget)
                      }
                      style={linkStyle}
                      sx={{cursor: 'pointer'}}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {webLinkItem.title}
                    </MuiLink>
                  ) : (
                    <Link
                      title={webLinkItem.title}
                      // to={`GenericContent/${to}`}
                      to={
                        existingIncustom
                          ? to
                          : `GenericContent/${webLinkItem.uniqueName}`
                      }
                      style={linkStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {webLinkItem.title}
                    </Link>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Stack gap={2}>
          <Typography variant={"h5"}>{t("ourlocation")}</Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: props.ourLocationData,
            }}
          ></Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FooterPrimaryInforamtion;
