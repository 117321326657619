import { useQuery } from "@tanstack/react-query";
import { MenuDM } from "../types/index";
import API from "../../client/api";
import { MENU } from "../queryKeyes";


const menuFetching = async (
  languageId: number,
): Promise<MenuDM[]> => {
  const response = await API.get(
    `SiteStructure/GetMenu?langId=${languageId}`
  );
  return response.data;
};
const useMenu = (languageId: number) => {
  return useQuery<MenuDM[], Error>(
    [MENU, languageId],
    () => menuFetching(languageId)
  );
};

export default useMenu;
