import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguageID } from "../../hooks/useLanguageID";
import useGenericContent from "../../hooks/useGenericContent";
import { Box, Button, Stack, Typography } from "@mui/material";
import { COLORS } from "../../constants";
import SearchNewsBanner from "../../../pages/newsList/components/SearchNewsBanner";
import CustomSkeleton from "../skelton/CustomSkeleton";

const newsAndUpdates1 = require("../../../assets/images/desktop.png");

interface GenericContentProp {}
const GenericContent: React.FC<GenericContentProp> = (props) => {
  const { t } = useTranslation();
  const nagivate = useNavigate();
  const { uniquename } = useParams();
  const languageId = useLanguageID();

  const { data, isLoading, isError, error } = useGenericContent(
    languageId,
    uniquename!
  );
  const lang = languageId === 1 ? "en-US" : "ar";
  const date =
    data?.lastModificationDate! && new Date(data?.lastModificationDate!);
  const formattedDate =
    date &&
    new Intl.DateTimeFormat(lang, {
      dateStyle: "medium",
    }).format(date);
    if(isLoading){
      return <CustomSkeleton />
    }
    if(isError){
      return null;
    }
  // const contentData =
  //   data &&
  //   data.contentData &&
  //   data.contentData.replace(
  //     "/NGTAC5",
  //     "http://demoserver.tacme.net:17600/NGTAC5"
  //   );
  
  return (
    <>
      <SearchNewsBanner
        mainImageFullUrl={data?.mainImageFullUrl}
        bannerTitle={t("")}
        showIcons={true}
        showPath={false}
      />
      <Box marginX={"8%"} marginTop={6}>
        <Stack
          spacing={2}
          paddingBottom={2}
          borderBottom={`2px solid ${COLORS.secondary}`}
        >
          <Box>
            {/* <Typography fontWeight={"bold"} variant="subtitle2">
            {formattedDate}
          </Typography> */}
            <Typography
              fontSize={"1.5rem"}
              fontWeight={"bold"}
              variant="subtitle2"
            >
              {data?.title}
            </Typography>
          </Box>
          {data?.relatedImages &&
            data.relatedImages.length > 0 &&
            data.relatedImages[0].fullUrl !== "" && (
              <Box
                sx={{
                  width: 240,
                  height: 230,
                  // padding: 1,
                  border: `1px solid ${COLORS.secondary}`,
                }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  // src={data?.mainImageFullUrl}
                  src={data.relatedImages[0].fullUrl}
                />
              </Box>
            )}
          <Typography
            dangerouslySetInnerHTML={{ __html: data?.contentData ?? "" }}
          />
        </Stack>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button onClick={() => nagivate(-1)} sx={{ fontSize: 14 }}>
            {t("backtohome")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GenericContent;
