import React from "react";
import {
  Box,
  ButtonBase,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  SubscriptionsOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";

import useMediaCenter from "../hooks/useMediaCenter";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import { COLORS } from "../../../shared/constants";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import MediaCenterLatestNews from "./pureComponents/MediaCenterLatestNews";

const desktop = require("../../../assets/images/desktop.png");
interface MediaCenterSectionProp {}

const MediaCenterSection: React.FC<MediaCenterSectionProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [hovered, setHovered] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const languageId = useLanguageID();
  const {
    imagegalleryRQR,
    videogalleryRQR,
    magazineRQR,
    brochureRQR,
    imageGallerySummaryRQR,
    videoGallerySummaryRQR,
  } = useMediaCenter(languageId);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const imageStyle = {
    cursor: hovered ? "pointer" : "default",
  };
  if (
    imagegalleryRQR.isLoading ||
    videogalleryRQR.isLoading ||
    magazineRQR.isLoading ||
    brochureRQR.isLoading ||
    imageGallerySummaryRQR.isLoading ||
    videoGallerySummaryRQR.isLoading
  ) {
    return <CustomSkeleton />;
  }

  if (
    imagegalleryRQR.isError &&
    videogalleryRQR.isError &&
    magazineRQR.isError &&
    brochureRQR.isError &&
    imageGallerySummaryRQR.isError &&
    videoGallerySummaryRQR.isError
  ) {
    return null;
  }
  return (
    <Box display={"flex"} flexDirection={"column"} gap={3} id="media-center">
      <Typography
        variant="h4"
        sx={{
          display: "block",
          marginTop: "1%",
        }}
      >
        {t("mediacenter")}
      </Typography>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "inherit",
          display: { xs: "none", xl: "block" },
        }}
      >
        <Grid
          container
          height={560}
          sx={{ backgroundColor: "inherite" }}
          justifyContent={"space-between"}
          spacing={1.5}
        >
          <Grid
            paddingLeft={theme.direction === "ltr" ? 0 : 1}
            paddingRight={theme.direction === "ltr" ? 1 : 0}
            item
            xs={12}
            lg={6}
            maxHeight={560}
            height={{ xs: "50%", sm: "50%", md: "50%", lg: "100%", xl: "100%" }}
          >
            {imageGallerySummaryRQR.data?.contentData ? (
              <Box height={"10%"}>
                <Typography
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: imageGallerySummaryRQR.data?.contentData!,
                  }}
                />
              </Box>
            ) : null}

            <Carousel
              sx={{
                height:
                  videoGallerySummaryRQR.data?.contentData ||
                  videoGallerySummaryRQR.data?.contentData
                    ? "90%"
                    : "100%",
                position: "relative",
              }}
              indicators={true}
              activeIndicatorIconButtonProps={{
                style: {
                  color: COLORS.primary,
                },
              }}
              indicatorContainerProps={{
                style: {
                  position: "absolute",
                  zIndex: 1,
                  top: "90%",
                  left: 0,
                  right: 0,
                  // marginTop: "34%",
                },
              }}
            >
              {imagegalleryRQR?.data?.map((item, i) => (
                <Box key={i} height={"100%"}>
                  <CardMedia
                    component="img"
                    sx={{
                      borderBottom: "none",
                      position: "relative",
                      width: "100%",
                      height: 560,
                    }}
                    image={item.mainImageFullUrl}
                    alt="image"
                  />
                  {/* <img src={item.mainImageFullUrl} /> */}
                  <Box
                    position={"absolute"}
                    top={"80%"}
                    right={theme.direction === "ltr" ? "35%" : "-35%"}
                    width={"100%"}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      paddingX={2}
                    >
                      <Typography
                        fontSize={"2.7vw"}
                        variant={"h3"}
                        color={COLORS.white}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Grid>

          <Grid
            paddingLeft={theme.direction === "ltr" ? 1 : 0}
            paddingRight={theme.direction === "ltr" ? 0 : 1}
            item
            xs={12}
            xl={6}
            height={{ xs: "50%", lg: "100%" }}
          >
            {videoGallerySummaryRQR.data?.contentData ? (
              <Box height={"10%"}>
                <Typography
                  mb={2}
                  dangerouslySetInnerHTML={{
                    __html: videoGallerySummaryRQR.data?.contentData!,
                  }}
                />
              </Box>
            ) : null}

            <Stack
              spacing={0}
              height={
                videoGallerySummaryRQR.data?.contentData ||
                imageGallerySummaryRQR.data?.contentData
                  ? "90%"
                  : "100%"
              }
            >
              {videogalleryRQR.data && videogalleryRQR.data.length > 0 && (
                <Box
                  position={"relative"}
                  sx={{ height: "55%" }}
                  style={imageStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    width={"100%"}
                    height={"100%"}
                    src={videogalleryRQR.data[0].mainImageFullUrl}
                    title={videogalleryRQR.data[0].title}
                  />
                  <Box position={"absolute"} top={"40%"} width={"100%"}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      paddingX={2}
                    >
                      <Box onClick={handleOpen}>
                        <SubscriptionsOutlined
                          titleAccess={videogalleryRQR.data[0].title}
                          sx={{
                            // color: theme.palette.primary.main,
                            color: COLORS.opacityBlack,
                            width: "9vh",
                            height: "9vh",
                          }}
                        />
                      </Box>
                      <Dialog open={open} onClose={handleClose} maxWidth="md">
                        <DialogContent sx={{ padding: 0 }}>
                          <iframe
                            width={isSmallScreen ? "350" : "550"}
                            height={isSmallScreen ? "215" : "315"}
                            src={
                              videogalleryRQR.data[0].relatedEntities[0]
                                ?.fullUrl
                            }
                            title={
                              videogalleryRQR.data[0].relatedEntities[0]?.title
                            }
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </DialogContent>
                      </Dialog>
                    </Box>
                  </Box>
                </Box>
              )}
              <Stack
                pt={2}
                sx={{ height: "45%" }}
                direction={"row"}
                spacing={1}
                gap={2}
              >
                {magazineRQR.data && magazineRQR.data.length > 0 && (
                  <Box height={"100%"} sx={{ flex: 1 }} position={"relative"}>
                    <ButtonBase
                      sx={{ width: "100%", height: "100%" }}
                      onClick={() =>
                        window.open(magazineRQR.data[0].linkUrl, "_blank")
                      }
                      title={magazineRQR.data[0].title}
                    >
                      <img
                        width={"100%"}
                        height={"100%"}
                        src={magazineRQR.data[0].fullUrl}
                      />
                    </ButtonBase>
                  </Box>
                )}
                {brochureRQR.data && brochureRQR.data.length > 0 && (
                  <Box height={"100%"} sx={{ flex: 1 }} position={"relative"}>
                    <ButtonBase
                      sx={{ width: "100%", height: "100%" }}
                      onClick={() =>
                        window.open(brochureRQR.data[0].linkUrl, "_blank")
                      }
                      title={brochureRQR.data[0].title}
                    >
                      <img
                        width={"100%"}
                        height={"100%"}
                        src={brochureRQR.data[0].fullUrl}
                      />
                    </ButtonBase>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>

      {/* ================================================================================================= */}
      <Stack
        spacing={1}
        sx={{
          backgroundColor: "inherit",
          display: { xs: "block", xl: "none" },
        }}
      >
        {imageGallerySummaryRQR.data?.contentData ? (
          <Box>
            <Typography
              dangerouslySetInnerHTML={{
                __html: imageGallerySummaryRQR.data?.contentData!,
              }}
            />
          </Box>
        ) : null}
        <Box
          sx={{
            width: "100%",
            // height: 280,
            height: { xs: 280, sm: 420, md: 580 },
            "@media print": {
              height: 580,
            },
            backgroundColor: "inherit",
          }}
        >
          <Carousel
            sx={{ height: "100%", position: "relative" }}
            indicators={true}
            activeIndicatorIconButtonProps={{
              style: {
                color: COLORS.primary,
              },
            }}
            indicatorContainerProps={{
              style: {
                position: "absolute",
                zIndex: 1,
                top: "88%",
                left: 0,
                right: 0,
              },
            }}
          >
            {imagegalleryRQR?.data?.map((item, i) => (
              <Box key={i} height={"100%"}>
                <CardMedia
                  height={"90%"}
                  component="img"
                  sx={{
                    borderBottom: "none",
                    position: "relative",
                    minHeight: 280,
                  }}
                  image={item.mainImageFullUrl}
                  alt="image"
                />
                {/* <img src={item.mainImageFullUrl} /> */}
                <Box
                  position={"absolute"}
                  top={"80%"}
                  right={theme.direction === "ltr" ? "35%" : "-35%"}
                  width={"100%"}
                >
                  <Box display={"flex"} justifyContent={"center"} paddingX={2}>
                    <Typography
                      fontSize={"2.7vw"}
                      variant={"h3"}
                      color={COLORS.white}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
        {videoGallerySummaryRQR.data?.contentData ? (
          <Box>
            <Typography
              dangerouslySetInnerHTML={{
                __html: videoGallerySummaryRQR.data?.contentData!,
              }}
            />
          </Box>
        ) : null}
        {videogalleryRQR.data && videogalleryRQR.data.length > 0 && (
          <Box
            position={"relative"}
            style={imageStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            height={{ xs: "100%", md: 400 }}
          >
            <img
              width={"100%"}
              height={"100%"}
              src={videogalleryRQR.data[0].mainImageFullUrl}
              title={videogalleryRQR.data[0].title}
            />
            <Box position={"absolute"} top={"40%"} width={"100%"}>
              <Box display={"flex"} justifyContent={"center"} paddingX={2}>
                <Box onClick={handleOpen}>
                  <SubscriptionsOutlined
                    titleAccess={videogalleryRQR.data[0].title}
                    sx={{
                      // color: theme.palette.primary.main,
                      color: COLORS.opacityBlack,
                      width: "9vh",
                      height: "9vh",
                    }}
                  />
                </Box>
                <Dialog open={open} onClose={handleClose} maxWidth="md">
                  <DialogContent sx={{ padding: 0 }}>
                    <iframe
                      width={isSmallScreen ? "350" : "550"}
                      height={isSmallScreen ? "215" : "315"}
                      src={videogalleryRQR.data[0].relatedEntities[0]?.fullUrl}
                      title={videogalleryRQR.data[0].relatedEntities[0]?.title}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </DialogContent>
                </Dialog>
              </Box>
            </Box>
          </Box>
        )}
        <Stack direction={"row"} spacing={1} height={{ xs: "100%", md: 350 }}>
          {magazineRQR.data && magazineRQR.data.length > 0 && (
            <Box height={"100%"} sx={{ flex: 1 }}>
              <ButtonBase
                sx={{ width: "100%", height: "100%" }}
                onClick={() =>
                  window.open(magazineRQR.data[0].linkUrl, "_blank")
                }
                title={magazineRQR.data[0].title}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  src={magazineRQR.data[0].fullUrl}
                />
              </ButtonBase>
            </Box>
          )}
          {brochureRQR.data && brochureRQR.data.length > 0 && (
            <Box height={"100%"} sx={{ flex: 1 }}>
              <ButtonBase
                title={brochureRQR.data[0].title}
                sx={{ width: "100%", height: "100%" }}
                onClick={() =>
                  window.open(brochureRQR.data[0].linkUrl, "_blank")
                }
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  src={brochureRQR.data[0].fullUrl}
                />
              </ButtonBase>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default MediaCenterSection;
