import React from "react";
import { useTheme } from "@mui/material";
import Joyride, {
  STATUS,
  CallBackProps,
} from "react-joyride";
import { ThemeContext } from "../../../context/ThemeContext";
import useGuidedTourCookie from "../../../shared/hooks/useGuidedTourCookie";
import useTourSteps from "../hooks/useTourSteps";
import { useTranslation } from "react-i18next";

interface NationalGuardTourProp {}
const NationalGuardTour: React.FC<NationalGuardTourProp> = (props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const { setGuidedTourCookie } = useGuidedTourCookie();
  const { isStartTour, startTourSetGet } = React.useContext(ThemeContext);
  const NGsteps = useTourSteps();


  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    setGuidedTourCookie();
    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      window.scrollTo(0, 0);
      startTourSetGet(false);
    }
  };
  return (
    <>
      {isStartTour && (
        <Joyride
          continuous
          callback={handleJoyrideCallback}
          run={true}
          steps={NGsteps}
          scrollToFirstStep={false}
          hideCloseButton
          showSkipButton
          locale={{ back: t('prev_step'), last: t('end_tour'),next: t('next_step'),skip: t('skip') }}
          styles={{
            tooltip: {direction:theme.direction},
            buttonNext: {
              paddingInline: 10,
            },
            options: {
              zIndex: 1302,
              primaryColor: theme.palette.primary.main
            },
          }}
        />
      )}
    </>
  );
};

export default NationalGuardTour;
