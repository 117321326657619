import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { COLORS } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
interface ImageGallerySearchFormProp {
  refetchHandler: () => void;
  onChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
const ImageGallerySearchForm: React.FC<ImageGallerySearchFormProp> = (
  props
) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid
      paddingX={"4%"}
      paddingTop={"3%"}
      paddingBottom={"4%"}
      container
      gap={2}
      sx={{
        backgroundImage: `linear-gradient(to bottom,${theme.palette.primary.main} , ${COLORS.white})`,
      }}
    >
      <Grid
        item
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={3}
      >
        <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
          <Typography variant="subtitle2">{t("search")}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            size="small"
            placeholder={t("enterkeyword")}
            variant="outlined"
            onChange={(event) => props.onChangeHandler(event)}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <Button
            fullWidth
            onClick={props.refetchHandler}
            variant="contained"
            sx={{ color: COLORS.white }}
          >
            {t("search")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageGallerySearchForm;
