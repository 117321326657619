import React, { KeyboardEvent } from "react";
import {
  Typography,
  InputBase,
  InputAdornment,
  styled,
  IconButton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { COLORS } from "../../../constants";
import { useNavigate } from "react-router-dom";

interface HeaderSearchSectionProp {}

const StyledSearch = styled("div")(({ theme }) => ({
  backgroundColor: COLORS.white,
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  marginInline: "1%",
}));
const HeaderSearchSection: React.FC<HeaderSearchSectionProp> = (props) => {
  const { t } = useTranslation();
  const nagivate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const handleNavigate = (to: string) => {
    nagivate(`Search/${to}`);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleNavigate(searchValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleAdornmentClick = () => {
    handleNavigate(searchValue);
  };
  return (
    <>
      <Typography width={'25%'} display={{ xs: "none", md: "block" }} color={COLORS.white}>
        {t("advance_search")}
      </Typography>
      <StyledSearch>
        <InputBase
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleAdornmentClick}>
                <Search />
              </IconButton>
            </InputAdornment>
          }
          placeholder={t("search")}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      </StyledSearch>
    </>
  );
};

export default HeaderSearchSection;
