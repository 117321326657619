import { useMutation } from "@tanstack/react-query";
import API from "../../../client/api";
import { ComplaintsPost } from "../types";

type FormResponse = {
  status: string;
  messageEn: string;
  messageAr: string;
};

export const ComplaintPost = async (
  data: ComplaintsPost
): Promise<FormResponse> => {
  const response = await API.post(`Feedback/PostFeedback`, {
    name: data.name,
    email: data.email,
    phone: data.phone,
    comment: data.comment,
    categoryUniqueName: data.categoryUniqueName,
    langId: data.langId,
    nationalityId: data.nationalityId,
    userStatus: data.userStatus,
  });
  return response.data;
};
const useComplaintsMutation = () => {
  const { data, isError, error, isLoading, mutate } = useMutation<
    FormResponse,
    Error,
    ComplaintsPost
  >(ComplaintPost);

  const submit = async (dataPassed: ComplaintsPost) => {
    await mutate(dataPassed);
  };
  return {
    submit,
    isComplaintMutationError: isError,
    ComplaintMutationError: error,
    ComplaintMutationIsLoading: isLoading,
    ComplaintMutationData: data,
  };
};

export default useComplaintsMutation;
