import React, { useState } from "react";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { EventsPage, SearchEventsVM } from "./types";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PAGE_SIZE, fetchEvents } from "./utils";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import NoMatch from "../noMatch/NoMatch";
import SearchEventsBanner from "./components/SearchEventsBanner";
import SearchEventsForm from "./components/SearchEventsForm";
import { COLORS } from "../../shared/constants";
import { DateFormatedByLanguage } from "../../shared/utils";

interface EventsListProp {}
const EventsList: React.FC<EventsListProp> = (props) => {
  const { t } = useTranslation();
  const nagivate = useNavigate();
  const theme = useTheme();
  const languageId = useLanguageID();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchNewsState, setSearchNewsState] = useState<SearchEventsVM>({
    key: "",
    category: "",
    dateFrom: "",
    dateTo: "",
  });

  const {
    data,
    isLoading,
    isError,
    refetch,
  }: UseQueryResult<EventsPage, Error> = useQuery(
    ["evetns", pageNumber, languageId],
    () =>
      fetchEvents(
        pageNumber,
        languageId,
        searchNewsState.key,
        searchNewsState.category,
        searchNewsState.dateFrom,
        searchNewsState.dateTo
      )
  );
  const location = useLocation();
  const bannerImage =
    location.state &&
    (location.state as any).bannerImage &&
    (location.state as any).bannerImage.toString();
  const refetchPassed = () => {
    refetch();
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <NoMatch />;
  }

  const { result, totalCount } = data;
  const pageCount = Math.ceil(totalCount / PAGE_SIZE);

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  const getPageButtonRange = () => {
    let firstPage = Math.max(1, pageNumber - 2);
    let lastPage = Math.min(pageCount, pageNumber + 2);

    if (firstPage === 1) {
      lastPage = Math.min(pageCount, 5);
    }

    if (lastPage === pageCount) {
      firstPage = Math.max(1, pageCount - 4);
    }

    return Array.from(
      { length: lastPage - firstPage + 1 },
      (_, i) => firstPage + i
    );
  };
  const lang = theme.direction === "ltr" ? "en-US" : "ar";
  const maxLength = 350;
  return (
    <>
      <SearchEventsBanner
        bannerTitle={t("eventslist")}
        showIconsAndPath={true}
        mainImageFullUrl={bannerImage}
      />
      <Box marginX={"8%"} marginTop={6}>
        <Stack marginY={6} spacing={6}>
          <SearchEventsForm
            refetchPassed={refetchPassed}
            searchNewsState={searchNewsState}
            setSearchNewsState={setSearchNewsState}
          />
        </Stack>
        <Typography letterSpacing={1} color={"primary"}>
          {`${t("showing")} ${
            PAGE_SIZE < totalCount ? PAGE_SIZE : totalCount
          } ${t("of")} ${totalCount} ${t("results")}`}
        </Typography>
        <Box mt={1}>
          {result.map((eventsItem) => {
            const formattedDate = DateFormatedByLanguage(eventsItem.eventStartDate,languageId)

            const html = eventsItem.contentData;
            const text = html.replace(/<[^>]+>/g, ""); // Removes all HTML tags from the text
            const truncatedText =
              text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
            return (
              <Card
                key={eventsItem.uniqueName}
                sx={{
                  display: "flex",
                  flexDirection:{xs: 'column',md:'row'},
                  gap: 3,
                  backgroundColor: "inherit",
                  borderBottom: `2px solid ${COLORS.secondary}`,
                  paddingBottom: 2,
                  marginY: 2,
                }}
                elevation={0}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    maxWidth: 280,
                    // padding: 1,
                    border: `1px solid ${COLORS.secondary}`,
                  }}
                >
                  <IconButton
                    onClick={() => nagivate(`${eventsItem.uniqueName}`)}
                    sx={{ width: "100%", height: 200, padding: 0 }}
                  >
                    <img
                      width={"100%"}
                      height={200}
                      src={eventsItem.mainImageFullUrl}
                    />
                  </IconButton>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="body1"
                      fontWeight={"bold"}
                    >
                      {formattedDate}
                    </Typography>
                    <Typography variant="body1" component="div" mt={1}>
                      {eventsItem.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      color="text.secondary"
                      dangerouslySetInnerHTML={{ __html: truncatedText }}
                      mt={1}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      sx={{ textDecoration: "underline" }}
                      onClick={() => nagivate(`${eventsItem.uniqueName}`)}
                      // onClick={() => {}}
                    >
                      {t("readmore")}
                    </Button>
                  </CardActions>
                </Box>
              </Card>
            );
          })}
        </Box>

        <Box
          display="flex"
          flexDirection={{xs:'column',sm:'row'}}
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <ButtonGroup aria-label="outlined button group">
            <Button
              variant="text"
              onClick={() => handlePageChange(1)}
              disabled={pageNumber === 1}
            >
              {t("first")}
            </Button>
            <Button
              variant="text"
              onClick={() => handlePageChange(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              {t("previous")}
            </Button>
          </ButtonGroup>

          <Box marginX={0}>
            {getPageButtonRange().map((page) => (
              <Button
                // sx={{ marginX: 1 }}
                key={page}
                onClick={() => handlePageChange(page)}
                disabled={pageNumber === page}
              >
                {page}
              </Button>
            ))}
          </Box>

          <ButtonGroup aria-label="outlined button group">
            <Button
              variant="text"
              onClick={() => handlePageChange(pageNumber + 1)}
              disabled={pageNumber === pageCount}
            >
              {t("next")}
            </Button>
            <Button
              variant="text"
              onClick={() => handlePageChange(pageCount)}
              disabled={pageNumber === pageCount}
            >
              {t("last")}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export default EventsList;
