import React from "react";
import { useTranslation } from "react-i18next";
import useSupportedBy from "../hooks/useSupportedBy";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import { Box, Grid, Typography } from "@mui/material";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
interface SupportedBySectionProp {}
const SupportedBySection: React.FC<SupportedBySectionProp> = (props) => {
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useSupportedBy(languageId);

  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const imageStyle = {
    cursor: hovered ? "pointer" : "default",
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }
  if (isError || !data || data.length === 0) {
    return null;
  }
  return (
    <Box>
      {data && data.length > 0 && (
        <>
          <Typography
            variant="h3"
            align="center"
            sx={{
              display: "block",
              marginTop: "1%",
            }}
          >
            {t("supportedby")}
          </Typography>
          <Grid
            container
            sx={{ marginTop: 5 }}
            gap={5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {data?.map((item, i) => {
              return (
                <Grid
                  item
                  key={i}
                  sm={12}
                  md={3}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <img
                    style={imageStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    src={item.imageFullUrl}
                    alt="image"
                    onClick={() =>
                      window.open(item.linkUrl, item.linkTarget ?? "_blank")
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SupportedBySection;
