import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import { useLanguageID } from "../../shared/hooks/useLanguageID";
import useEventByUniqueName from "./hooks/useEventByUniqueName";
import { COLORS } from "../../shared/constants";
import { useTranslation } from "react-i18next";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import NoMatch from "../noMatch/NoMatch";
interface EventDetailsProp {}
const EventDetails: React.FC<EventDetailsProp> = (props) => {
  const {t} = useTranslation();
  const nagivate = useNavigate();
  const { uniquename } = useParams();
  const languageId = useLanguageID();

  const { data, isLoading, isError, error } = useEventByUniqueName(
    languageId,
    uniquename!
  );
  if (isLoading) {
    return <CustomSkeleton />;
  }
  
  if (isError) {
    return <NoMatch />;
  }
  const lang = languageId === 1 ? "en-US" : "ar";
  const date =data?.eventStartDate! && new Date(data?.eventStartDate!);
  const formattedDate = date && new Intl.DateTimeFormat(lang, {
    dateStyle: "medium",
  }).format(date);
  return (
    <Box marginX={"8%"} marginTop={6}>
      <Stack spacing={2} paddingBottom={2} borderBottom={`2px solid ${COLORS.secondary}`}>
        <Box>
          <Typography fontWeight={'bold'} variant="h5">{formattedDate}</Typography>
          <Typography fontWeight={"bold"} variant="h5">
            {data?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            // width: "35%",
            maxWidth:480,
            border: `1px solid ${COLORS.secondary}`,
          }}
        >
          <img width={'100%'} height={'auto'} src={data?.mainImageFullUrl}/>
        </Box>
        <Typography dangerouslySetInnerHTML={{ __html: data?.contentData! }} />
      </Stack>
      <Box display={'flex'} justifyContent={'flex-end'}>
      <Button onClick={() => nagivate(-1)} sx={{fontSize:14}}>{t('backtoevents')}</Button>
      </Box>
    </Box>
  );
};

export default EventDetails;
