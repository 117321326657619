import { Typography } from "@mui/material";
import React from "react";
interface FormTitleProp {
  title: string;
}
const FormTitle: React.FC<FormTitleProp> = (props) => {
  return (
    <Typography
    variant="h4"
    fontWeight={"500"}
    sx={{
      display: "block",
      marginTop: 2,
      marginBottom: 3,
    }}
  >
    {props.title}
  </Typography>
  );
};

export default FormTitle;
