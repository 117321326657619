import React from "react";
import { Divider, Stack } from "@mui/material";
import NewsLatestSection from "./components/NewsLatestSection";
import TryToAttendSection from "./components/TryToAttendSection";
import MediaCenterSection from "./components/MediaCenterSection";
import HomeTopBanners from "./components/HomeTopBanners";
import MessagesSection from "./components/MessagesSection";
// import WelcomPopup from "./components/WelcomPopup";

interface HomeIndexProp {}
const HomeIndex: React.FC<HomeIndexProp> = (props) => {
  return (
    <>
      <HomeTopBanners />
      <Stack spacing={9} marginX={"8%"} mt={6}>
        <MessagesSection />
        <NewsLatestSection />
      </Stack>
      <TryToAttendSection />
      <Stack spacing={6} marginX={"8%"} mt={6}>
        <Divider sx={{ borderBottomWidth: 3 }} />
        <MediaCenterSection />
      </Stack>
      {/* <WelcomPopup /> */}
    </>
  );
};

export default HomeIndex;
