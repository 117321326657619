import { ComplaintsPost } from "../types";

export const initComplaintsPost: ComplaintsPost = {
  name: "",
  email: "",
  phone: "",
  comment: "",
  categoryUniqueName: "",
  langId: 1,
  nationalityId: 0,
  userStatus: "",
};
