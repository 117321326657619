import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS, customPages } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import useSearchCategory from "../hooks/useSearchCategory";
import { AdvanceSearchItem } from "../types";

interface AdvanceSearchContaierProp {
  result: AdvanceSearchItem[];
}
const AdvanceSearchContaier: React.FC<AdvanceSearchContaierProp> = (props) => {
  const { searchvalue } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const languageId = useLanguageID();
  const navigate = useNavigate();
  const [key, setKey] = React.useState(searchvalue ?? "");
  const [hovered, setHovered] = React.useState(false);
  const { data, isError, isLoading } = useSearchCategory(languageId);
  if (isLoading) {
    return <CustomSkeleton />;
  }
  if (isError) {
    return null;
  }
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const imageStyle = {
    cursor: hovered ? "pointer" : "default",
  };
  const options =
    data?.map((item) => ({
      id: item.id,
      name: item.name,
    })) ?? [];

  const navigateHandler = (uniqueName: string, moduleName: string) => {
    const uniqueNameSplitting = uniqueName.split('/');
    uniqueName = uniqueNameSplitting[uniqueNameSplitting.length - 1].replace('.aspx','');
    const baseUrl =
      moduleName.toLowerCase() === "news" ||
      moduleName.toLowerCase() === "events"
        ? moduleName
        : "GenericContent";
    const isExist = customPages.includes(uniqueName);
    const To = isExist ? `/${uniqueName}` : `/${baseUrl}/${uniqueName}`;
    navigate(To);
  };

  const defaultOption = options.find((option) => option.name === "All");
  return (
    <Box mt={5}>
      <Grid container>
        {props.result.map((item, index) => {
          return (
            <Grid key={index} xs={4} item height={320} padding={1}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                style={imageStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() =>
                  navigateHandler(item.uniqueName, item.moduleName)
                }
              >
                <CardContent sx={{ height: "30%" }}>
                  <Typography variant="h6" component="div">
                    {item.title.length < 65
                      ? item.title
                      : `${item.title.substring(0, 65)}...`}
                  </Typography>
                </CardContent>
                {/* <CardContent sx={{height:'10%'}}>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {getOurDate()}
                  </Typography>
                </CardContent> */}
                <CardContent sx={{ height: "52%" }}>
                  <Typography variant="body2">
                    {item.description.length < 110
                      ? item.description
                      : `${item.description.substring(0, 65)}...`}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      paddingX: 2,
                      paddingY: 1,
                      borderRadius: 20,
                      fontSize: 14,

                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item.moduleName}
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AdvanceSearchContaier;

export function getOurDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}
