const usePollsCookies = () => {
  const setPollsCookie = (): void => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
    // expirationDate.setTime(expirationDate.getTime() + 10000);
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = "polls" + "=" + "true" + "; " + expires;
  };

  const getPollsCookieValue = (): string | null => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      const cookieName = cookie[0];
      const cookieValue = cookie[1];
      if (cookieName === "polls") {
        return cookieValue;
      }
    }
    return null; // Cookie not found
  };
  return { getPollsCookieValue, setPollsCookie };
};

export default usePollsCookies;