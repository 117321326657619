import {
  AcUnitOutlined,
  CloudOutlined,
  NightlightOutlined,
  ThunderstormOutlined,
  WbSunnyOutlined,
} from "@mui/icons-material";
import React from "react";
import { COLORS } from "../../constants";
interface WeatherIconProp {
  iconCode: string;
}
const WeatherIcon: React.FC<WeatherIconProp> = (props) => {
  const getIcon = (iconCode: string) => {
    switch (iconCode) {
      case "01d":
        return <WbSunnyOutlined sx={{ color: COLORS.white }} />;
      case "01n":
        return <CloudOutlined sx={{ color: COLORS.white }} />;
      case "02d":
      case "02n":
        return <CloudOutlined sx={{ color: COLORS.white }} />;
      case "03d":
      case "03n":
      case "04d":
      case "04n":
        return <CloudOutlined sx={{ color: COLORS.white }} />;
      case "09d":
      case "09n":
        return <ThunderstormOutlined sx={{ color: COLORS.white }} />;
      case "10d":
      case "10n":
        return <ThunderstormOutlined sx={{ color: COLORS.white }} />;
      case "11d":
      case "11n":
        return <ThunderstormOutlined sx={{ color: COLORS.white }} />;
      case "13d":
      case "13n":
        return <AcUnitOutlined sx={{ color: COLORS.white }} />;
      default:
        return null;
    }
  };

  return getIcon(props.iconCode);
};

export default WeatherIcon;
