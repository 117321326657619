import React from "react";
import {
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import NGuardNavLink from "./NGuardNavLink";
import { MenuDM } from "../../../types";
import { customPages } from "../../../constants";

interface VerticalMenuProp {
  menuPages: MenuDM[];
}
const VerticalMenu: React.FC<VerticalMenuProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [menus, setMenus] = React.useState([""]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleAddToMenu = (contentUniquename: string) => {
    if (!menus.includes(contentUniquename)) {
      setMenus([...menus, contentUniquename]);
      // handleCloseNavMenu()
    }
  };
  const handleRemovesFromMenu = () => {
    setMenus([""]);
    handleCloseNavMenu();
  };
  const handleCloseSubSelect = () => {
    setMenus([""]);
  };
  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {props.menuPages.map((page) => {
          if (
            (page.contentUniquename === "" || !page.contentUniquename) &&
            page.title !== "home"
          ) {
            var splitVirtualPath = page.virtualPath.split("/");
            page.contentUniquename =
              splitVirtualPath[splitVirtualPath.length - 1];
          }
          return (
            <MenuItem
              key={page.contentUniquename}
              onClick={
                page.children && page.children.length > 0
                  ? () => {}
                  : handleRemovesFromMenu
              }
            >
              {page.children && page.children.length > 0 ? (
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <Select
                    value={page.contentUniquename ?? ""}
                    open={menus.includes(page.contentUniquename!)}
                    disableUnderline
                    variant="standard"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onClick={() => handleAddToMenu(page.contentUniquename!)}
                    onClose={handleCloseSubSelect}
                  >
                    <MenuItem
                      value={page.contentUniquename ?? ""}
                      dir={theme.direction}
                      sx={{
                        display: "none",
                        borderBottom: `2px solid #ddd`,
                        paddingBottom: 1,
                        marginInlineEnd: 2,
                      }}
                      onClick={handleCloseNavMenu}
                    >
                      <NavLink
                        style={{
                          color: "black",
                          display: "block",
                          textDecoration: "none",
                        }}
                        to={"#"}
                      >
                        {page.title}
                      </NavLink>
                    </MenuItem>
                    <Box minWidth={250}>
                      {page.children.map((subPage, index) => {
                        //#region contentUniquename
                        if (
                          subPage.contentUniquename === "" ||
                          !subPage.contentUniquename
                        ) {
                          var splitVirtualPath = page.virtualPath.split("/");
                          subPage.contentUniquename =
                            splitVirtualPath[splitVirtualPath.length - 1];
                        }
                        const existingIncustom = customPages.find(
                          (item) => item === subPage.contentUniquename
                        );
                        const borderBottom =
                          index === page.children.length - 1
                            ? ""
                            : `2px solid #ddd`;
                        //#endregion
                        return (
                          <Box
                            key={subPage.contentUniquename}
                            sx={{ direction: theme.direction }}
                          >
                            <MenuItem
                              key={subPage.contentUniquename}
                              value={subPage.contentUniquename ?? ""}
                              onClick={handleRemovesFromMenu}
                            >
                              <NGuardNavLink
                                bannerImage={subPage.mainImageFullUrl}
                                to={
                                  existingIncustom
                                    ? subPage.contentUniquename
                                    : `GenericContent/${subPage.contentUniquename}`
                                }
                                title={subPage.title}
                                borderBottom={borderBottom}
                                paddingBottom={8}
                              />
                            </MenuItem>
                          </Box>
                        );
                      })}
                    </Box>
                  </Select>
                </FormControl>
              ) : (
                <NGuardNavLink
                  bannerImage={page.mainImageFullUrl}
                  to={
                    customPages.find((item) => item === page.contentUniquename)
                      ? page.contentUniquename!
                      : `GenericContent/${page.contentUniquename}`
                  }
                  title={t(page.title)}
                />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default VerticalMenu;
