import { Box, Skeleton } from "@mui/material";
import React from "react";

interface CustomSkeletonProp {}

const CustomSkeleton: React.FC<CustomSkeletonProp> = (props) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} mx={'5%'} mt={6}>
      <Skeleton variant="rectangular" width={"70%"} height={45} />
      <Skeleton variant="rectangular" width={"80%"} height={40} />
      <Skeleton variant="rounded" width={"55%"} height={40} />
      <Skeleton variant="rounded" width={"70%"} height={40} />
    </Box>
  );
};

export default CustomSkeleton;
