import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useLanguageID } from "../../shared/hooks/useLanguageID";
import useImgageGallerySearch, {
  IMAGE_GALLERYPAGE_SIZE,
} from "./hooks/useImgageGallerySearch";
import CustomSkeleton from "../../shared/components/skelton/CustomSkeleton";
import NoMatch from "../noMatch/NoMatch";
import Carousel from "react-material-ui-carousel";
import SubPageTitle from "../../shared/components/typographies/SubPageTitle";
import FormTitle from "../../shared/components/typographies/FormTitle";
import ImageGallerySearchForm from "./components/ImageGallerySearchForm";
import ImageGalleryContainer from "./components/ImageGalleryContainer";
import HomeTopBannersIcons from "../homeIndex/components/pureComponents/HomeTopBannersIcons";

interface ImageGalleryProp {}

const ImageGallery: React.FC<ImageGalleryProp> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const languageId = useLanguageID();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [key, setKey] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, isError, refetch } = useImgageGallerySearch(
    pageNumber,
    languageId,
    key
  );
  const [carouselData, setCarouselData] = React.useState(data?.result);
  const refetchHandler = () => {
    refetch();
  };
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setKey(event.target.value);
  };
  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return <NoMatch />;
  }
  const { result, totalCount } = data;

  const pageCount = Math.ceil(totalCount / IMAGE_GALLERYPAGE_SIZE);
  const handleOpen = (index: number) => {
    const from = result.slice(index, result.length);
    const to = result.slice(0, index);
    setCarouselData([...from, ...to]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber);
  };

  const getPageButtonRange = () => {
    let firstPage = Math.max(1, pageNumber - 2);
    let lastPage = Math.min(pageCount, pageNumber + 2);

    if (firstPage === 1) {
      lastPage = Math.min(pageCount, 5);
    }

    if (lastPage === pageCount) {
      firstPage = Math.max(1, pageCount - 4);
    }

    return Array.from(
      { length: lastPage - firstPage + 1 },
      (_, i) => firstPage + i
    );
  };
  const lang = theme.direction === "ltr" ? "en-US" : "ar";
  const maxLength = 350;
  return (
    <>
      <Box marginX={"8%"} marginTop={5}>
        <Box>
          <Stack spacing={5} mb={1}>
            <SubPageTitle title={t("imagegallery")} />
            <FormTitle title={t("filterBy")} />
          </Stack>
        </Box>

        <ImageGallerySearchForm
          onChangeHandler={onChangeHandler}
          refetchHandler={refetchHandler}
        />

        <ImageGalleryContainer handleOpen={handleOpen} result={result} />
        <Typography letterSpacing={1} color={"primary"} align="center" mt={4}>
          {`${t("showing")} ${
            IMAGE_GALLERYPAGE_SIZE < totalCount
              ? IMAGE_GALLERYPAGE_SIZE
              : totalCount
          } ${t("of")} ${totalCount} ${t("results")}`}
        </Typography>
        {result && result.length > 0 ? (
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="center"
            alignItems="center"
            marginTop={2}
          >
            <ButtonGroup aria-label="outlined button group">
              <Button
                onClick={() => handlePageChange(1)}
                disabled={pageNumber === 1}
                variant="text"
              >
                {t("first")}
              </Button>
              <Button
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
                variant="text"
              >
                {t("previous")}
              </Button>
            </ButtonGroup>

            <Box marginX={0}>
              {getPageButtonRange().map((page) => (
                <Button
                  // sx={{ marginX: 1 }}
                  key={page}
                  onClick={() => handlePageChange(page)}
                  disabled={pageNumber === page}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <ButtonGroup aria-label="outlined button group">
              <Button
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber === pageCount}
                variant="text"
              >
                {t("next")}
              </Button>
              <Button
                onClick={() => handlePageChange(pageCount)}
                disabled={pageNumber === pageCount}
                variant="text"
              >
                {t("last")}
              </Button>
            </ButtonGroup>
          </Box>
        ) : (
          <Typography textAlign={"center"} variant="h5" color={"primary"}>
            {t("zeroresult")}
          </Typography>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"lg"}
        fullWidth
        sx={{ height: "auto" }}
      >
        {/* <DialogContent sx={{ padding: 0, width: "100%" }}> */}
        <Carousel
          autoPlay={false}
          indicators={false}
          sx={{ height: "auto", width: "100%" }}
        >
          {carouselData?.map((imgItem, index) => {
            return (
              <img key={index} width={"100%"} src={imgItem.mainImageFullUrl} />
            );
          })}
        </Carousel>
        {/* </DialogContent> */}
      </Dialog>
    </>
  );
};

export default ImageGallery;
